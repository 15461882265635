import React from "react";
/**
 * Loading spinner component
 */
export default class ContentLoading extends React.Component {
  render() {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <i className="fas fa-fan fa-spin text-primary"></i>
      </div>
    );
  }
}
