// types of action
const Types = {
  // input visibility
  TOGGLE_INCLUDE_FREE_TEXT: 'TOGGLE_INCLUDE_FREE_TEXT',
  TOGGLE_INCLUDE_GENDER: 'TOGGLE_INCLUDE_GENDER',
  TOGGLE_INCLUDE_YEARS_OF_EXPERIENCE: 'TOGGLE_INCLUDE_YEARS_OF_EXPERIENCE',
  TOGGLE_INCLUDE_NATIONALITY: 'TOGGLE_INCLUDE_NATIONALITY',
  TOGGLE_INCLUDE_STATUS: 'TOGGLE_INCLUDE_STATUS',
  TOGGLE_INCLUDE_RELEVENT_POSITION: 'TOGGLE_INCLUDE_RELEVENT_POSITION',
  TOGGLE_INCLUDE_EXPERTISE_TYPE: 'TOGGLE_INCLUDE_EXPERTISE_TYPE',
  TOGGLE_INCLUDE_EDUCATION: 'TOGGLE_INCLUDE_EDUCATION',
  TOGGLE_INCLUDE_DONOR_EXPERIENCE: 'TOGGLE_INCLUDE_DONOR_EXPERIENCE',
  TOGGLE_INCLUDE_AGE: 'TOGGLE_INCLUDE_AGE',
  TOGGLE_INCLUDE_PROFILE_UPDATED: 'TOGGLE_INCLUDE_PROFILE_UPDATED',
  TOGGLE_INCLUDE_MISSIONS: 'TOGGLE_INCLUDE_MISSIONS',
  TOGGLE_INCLUDE_DRIVING_LICENCE: 'TOGGLE_INCLUDE_DRIVING_LICENCE',
  TOGGLE_INCLUDE_FSB_COURSES: 'TOGGLE_INCLUDE_FSB_COURSES',
  TOGGLE_INCLUDE_FSB_COURSES_YEARS: 'TOGGLE_INCLUDE_FSB_COURSES_YEARS',
  TOGGLE_INCLUDE_TECHNICAL_EXPERTISE: 'TOGGLE_INCLUDE_TECHNICAL_EXPERTISE',
  TOGGLE_INCLUDE_REGIONAL_EXPERIENCE: 'TOGGLE_INCLUDE_REGIONAL_EXPERIENCE',
  TOGGLE_INCLUDE_DOCUMENTS: 'TOGGLE_INCLUDE_DOCUMENTS',
  TOGGLE_INCLUDE_LANGUAGE_SKILLS: 'TOGGLE_INCLUDE_LANGUAGE_SKILLS',
  UPDATE_FREE_TEXT: 'UPDATE_FREE_TEXT',
  UPDATE_FREE_TEXT_TARGETS: 'UPDATE_FREE_TEXT_TARGETS',
  UPDATE_GENDER_OPTIONS: 'UPDATE_GENDER_OPTIONS',
  UPDATE_STATUS_OPTIONS: 'UPDATE_STATUS_OPTIONS',
  UPDATE_EXPERIENCE_YEARS_OPTIONS: 'UPDATE_EXPERIENCE_YEARS_OPTIONS',
  UPDATE_STATUS_FROM: 'UPDATE_STATUS_FROM',
  UPDATE_STATUS_TO: 'UPDATE_STATUS_TO',
  UPDATE_NATIONALITY_OPTIONS: 'UPDATE_NATIONALITY_OPTIONS',
  UPDATE_EXPERTISE_TYPE_OPTIONS: 'UPDATE_EXPERTISE_TYPE_OPTIONS',
  UPDATE_DRIVING_LICENCE_TYPE_OPTIONS: 'UPDATE_DRIVING_LICENCE_TYPE_OPTIONS',
  UPDATE_MIN_AGE: 'UPDATE_MIN_AGE',
  UPDATE_MAX_AGE: 'UPDATE_MAX_AGE',
  UPDATE_PROFILE_UPDATED_FROM: 'UPDATE_PROFILE_UPDATED_FROM',
  UPDATE_PROFILE_UPDATED_TO: 'UPDATE_PROFILE_UPDATED_TO',
  UPDATE_DOCUMENTS_OBTAINED_BEFORE: 'UPDATE_DOCUMENTS_OBTAINED_BEFORE',
  UPDATE_DOCUMENTS_OBTAINED_AFTER: 'UPDATE_DOCUMENTS_OBTAINED_AFTER',
  UPDATE_EXPIRING_DOCUMENT_OPTIONS: 'UPDATE_EXPIRING_DOCUMENT_OPTIONS',
  UPDATE_RELEVENT_POSITION_OPTIONS: 'UPDATE_RELEVENT_POSITION_OPTIONS',
  UPDATE_EDUCATION_OPTIONS: 'UPDATE_EDUCATION_OPTIONS',
  UPDATE_DONOR_OPTIONS: 'UPDATE_DONOR_OPTIONS',
  UPDATE_LANGUAGE_SKILLS: 'UPDATE_LANGUAGE_SKILLS',
  UPDATE_FSB_COURSES: 'UPDATE_FSB_COURSES',
  UPDATE_FSB_COURSE_STARTED: 'UPDATE_FSB_COURSE_STARTED',
  UPDATE_FSB_COURSE_ENDED: 'UPDATE_FSB_COURSE_ENDED',
  UPDATE_MISSIONS_EXPERT_STATUS: 'UPDATE_MISSIONS_EXPERT_STATUS',
  UPDATE_MISSIONS: 'UPDATE_MISSIONS',
  UPDATE_MISSION_FROM: 'UPDATE_MISSION_FROM',
  UPDATE_MISSION_TO: 'UPDATE_MISSION_TO',
  UPDATE_MISSION_PROJECTS: 'UPDATE_MISSION_PROJECTS',
  UPDATE_TECHNICAL_EXPERTISE: 'UPDATE_TECHNICAL_EXPERTISE',
  UPDATE_REGIONAL_EXPERIENCE: 'UPDATE_REGIONAL_EXPERIENCE',
  UPDATE_RESULT_COUNT: 'UPDATE_RESULT_COUNT',
  UPDATE_SEARCH_CRITERIA: 'UPDATE_SEARCH_CRITERIA',
  //Operators
  UPDATE_NATIONALITY_OPERATOR: 'UPDATE_NATIONALITY_OPERATOR',
  UPDATE_EXPERTISE_TYPE_OPERATOR: 'UPDATE_EXPERTISE_TYPE_OPERATOR',
  UPDATE_DRIVING_LICENCE_TYPE_OPERATOR: 'UPDATE_DRIVING_LICENCE_TYPE_OPERATOR',
  UPDATE_DOCUMENT_TYPE_OPERATOR: 'UPDATE_DOCUMENT_TYPE_OPERATOR',
  UPDATE_EDUCATION_TYPE_OPERATOR: 'UPDATE_EDUCATION_TYPE_OPERATOR',
  UPDATE_RELEVENT_POSITION_TYPE_OPERATOR:
    'UPDATE_RELEVENT_POSITION_TYPE_OPERATOR',
  UPDATE_DONOR_TYPE_OPERATOR: 'UPDATE_DONOR_TYPE_OPERATOR',
  UPDATE_LANGUAGE_SKILLS_OPERATOR: 'UPDATE_LANGUAGE_SKILLS_OPERATOR',
  UPDATE_FSB_COURSES_OPERATOR: 'UPDATE_FSB_COURSES_OPERATOR',
  UPDATE_TECHNICAL_EXPERTISE_OPERATOR: 'UPDATE_TECHNICAL_EXPERTISE_OPERATOR',
  UPDATE_REGIONAL_EXPERIENCE_OPERATOR: 'UPDATE_REGIONAL_EXPERIENCE_OPERATOR',
  CLEAR_SEARCH: 'CLEAR_SEARCH',
};

// actions
const updateFreeText = (data) => {
  return {
    type: Types.UPDATE_FREE_TEXT,
    payload: data,
  };
};

// Payload is not necessary as it will toggle old state.
const toggleIncludeFilter = (type) => {
  return {
    type: type,
  };
};

// Explicitly set a filter on or off.
const setIncludeFilter = (type, data) => {
  return {
    type: type,
    payload: data,
  };
};

const updateFreeTextTargets = (data) => {
  return {
    type: Types.UPDATE_FREE_TEXT_TARGETS,
    payload: data,
  };
};

const updateGenderOptions = (data) => {
  return {
    type: Types.UPDATE_GENDER_OPTIONS,
    payload: data,
  };
};

const updateExperienceYearsOptions = (data) => {
  return {
    type: Types.UPDATE_EXPERIENCE_YEARS_OPTIONS,
    payload: data,
  };
};

const updateStatusOptions = (data) => {
  return {
    type: Types.UPDATE_STATUS_OPTIONS,
    payload: data,
  };
};

const updateStatusFrom = (data) => {
  return {
    type: Types.UPDATE_STATUS_FROM,
    payload: data,
  };
};

const updateStatusTo = (data) => {
  return {
    type: Types.UPDATE_STATUS_TO,
    payload: data,
  };
};

const updateProfileUpdatedFrom = (data) => {
  return {
    type: Types.UPDATE_PROFILE_UPDATED_FROM,
    payload: data,
  };
};

const updateProfileUpdatedTo = (data) => {
  return {
    type: Types.UPDATE_PROFILE_UPDATED_TO,
    payload: data,
  };
};

const updateDocumentsObtainedBefore = (data) => {
  return {
    type: Types.UPDATE_DOCUMENTS_OBTAINED_BEFORE,
    payload: data,
  };
};

const updateDocumentsObtainedAfter = (data) => {
  return {
    type: Types.UPDATE_DOCUMENTS_OBTAINED_AFTER,
    payload: data,
  };
};

const updateNationalityOptions = (data) => {
  return {
    type: Types.UPDATE_NATIONALITY_OPTIONS,
    payload: data,
  };
};

const updateExpertiseTypeOptions = (data) => {
  return {
    type: Types.UPDATE_EXPERTISE_TYPE_OPTIONS,
    payload: data,
  };
};

const updateDrivingLicenseTypeOptions = (data) => {
  return {
    type: Types.UPDATE_DRIVING_LICENCE_TYPE_OPTIONS,
    payload: data,
  };
};

const updateTechnicalExpertise = (data) => {
  return {
    type: Types.UPDATE_TECHNICAL_EXPERTISE,
    payload: data,
  };
};

const updateRegionalExperience = (data) => {
  return {
    type: Types.UPDATE_REGIONAL_EXPERIENCE,
    payload: data,
  };
};

const updateFsbCourses = (includedOptions, excludedOptions) => {
  return {
    type: Types.UPDATE_FSB_COURSES,
    payload: {includedOptions, excludedOptions},
  };
};

const updateFsbCourseStarted = (data) => {
  return {
    type: Types.UPDATE_FSB_COURSE_STARTED,
    payload: data,
  };
};

const updateFsbCourseEnded = (data) => {
  return {
    type: Types.UPDATE_FSB_COURSE_ENDED,
    payload: data,
  };
};

const updateLanguageSkills = (data) => {
  return {
    type: Types.UPDATE_LANGUAGE_SKILLS,
    payload: data,
  };
};

const updateMissionExpertStatus = (data) => {
  return {
    type: Types.UPDATE_MISSIONS_EXPERT_STATUS,
    payload: data,
  };
};

const updateMissions = (data) => {
  return {
    type: Types.UPDATE_MISSIONS,
    payload: data,
  };
};
const updateMissionTo = (data) => {
  return {
    type: Types.UPDATE_MISSION_TO,
    payload: data,
  };
};

const updateMissionFrom = (data) => {
  return {
    type: Types.UPDATE_MISSION_FROM,
    payload: data,
  };
};
const updateMissionProjects = (data) => {
  return {
    type: Types.UPDATE_MISSION_PROJECTS,
    payload: data,
  };
};

const updateExpiringDocumentOptions = (data) => {
  return {
    type: Types.UPDATE_EXPIRING_DOCUMENT_OPTIONS,
    payload: data,
  };
};

const updateReleventPositionOptions = (data) => {
  return {
    type: Types.UPDATE_RELEVENT_POSITION_OPTIONS,
    payload: data,
  };
};

const updateEducationOptions = (data) => {
  return {
    type: Types.UPDATE_EDUCATION_OPTIONS,
    payload: data,
  };
};

const updateDonorOptions = (data) => {
  return {
    type: Types.UPDATE_DONOR_OPTIONS,
    payload: data,
  };
};

const updateMinAge = (data) => {
  return {
    type: Types.UPDATE_MIN_AGE,
    payload: data,
  };
};

const updateMaxAge = (data) => {
  return {
    type: Types.UPDATE_MAX_AGE,
    payload: data,
  };
};

const updateResultCount = (data) => {
  return {
    type: Types.UPDATE_RESULT_COUNT,
    payload: data,
  };
};

const updateSearchCriteria = (data) => {
  return {
    type: Types.UPDATE_SEARCH_CRITERIA,
    payload: data,
  };
};

// Operator actions
const updateNationalityOperator = (data) => {
  return {
    type: Types.UPDATE_NATIONALITY_OPERATOR,
    payload: data,
  };
};

const updateExpertiseTypeOperator = (data) => {
  return {
    type: Types.UPDATE_EXPERTISE_TYPE_OPERATOR,
    payload: data,
  };
};

const updateDrivingLicenseTypeOperator = (data) => {
  return {
    type: Types.UPDATE_DRIVING_LICENCE_TYPE_OPERATOR,
    payload: data,
  };
};

const updateDocumentTypeOperator = (data) => {
  return {
    type: Types.UPDATE_DOCUMENT_TYPE_OPERATOR,
    payload: data,
  };
};

const updateReleventPositionTypeOperator = (data) => {
  return {
    type: Types.UPDATE_RELEVENT_POSITION_TYPE_OPERATOR,
    payload: data,
  };
};

const updateEducationTypeOperator = (data) => {
  return {
    type: Types.UPDATE_EDUCATION_TYPE_OPERATOR,
    payload: data,
  };
};

const updateDonorTypeOperator = (data) => {
  return {
    type: Types.UPDATE_DONOR_TYPE_OPERATOR,
    payload: data,
  };
};

const updateLanguageSkillsOperator = (data) => {
  return {
    type: Types.UPDATE_LANGUAGE_SKILLS_OPERATOR,
    payload: data,
  };
};

const updateFsbCoursesOperator = (data) => {
  return {
    type: Types.UPDATE_FSB_COURSES_OPERATOR,
    payload: data,
  };
};

const updateTechnicalExpertiseOperator = (data) => {
  return {
    type: Types.UPDATE_TECHNICAL_EXPERTISE_OPERATOR,
    payload: data,
  };
};

const updateRegionalExperienceOperator = (data) => {
  return {
    type: Types.UPDATE_REGIONAL_EXPERIENCE_OPERATOR,
    payload: data,
  };
};

const clearSearch = () => {
  return {
    type: Types.CLEAR_SEARCH,
  };
};

export default {
  Types,
  updateFreeText,
  toggleIncludeFilter,
  setIncludeFilter,
  updateFreeTextTargets,
  updateGenderOptions,
  clearSearch,
  updateExperienceYearsOptions,
  updateStatusOptions,
  updateStatusFrom,
  updateStatusFrom,
  updateStatusTo,
  updateNationalityOperator,
  updateNationalityOptions,
  updateExpertiseTypeOperator,
  updateExpertiseTypeOptions,
  updateDrivingLicenseTypeOperator,
  updateDrivingLicenseTypeOptions,
  updateMinAge,
  updateMaxAge,
  updateProfileUpdatedFrom,
  updateProfileUpdatedTo,
  updateDocumentTypeOperator,
  updateExpiringDocumentOptions,
  updateDocumentsObtainedBefore,
  updateDocumentsObtainedAfter,
  updateReleventPositionTypeOperator,
  updateReleventPositionOptions,
  updateEducationTypeOperator,
  updateEducationOptions,
  updateDonorTypeOperator,
  updateDonorOptions,
  updateLanguageSkillsOperator,
  updateLanguageSkills,
  updateFsbCoursesOperator,
  updateFsbCourses,
  updateFsbCourseStarted,
  updateFsbCourseEnded,
  updateMissionExpertStatus,
  updateMissions,
  updateMissionTo,
  updateMissionFrom,
  updateMissionProjects,
  updateTechnicalExpertiseOperator,
  updateTechnicalExpertise,
  updateRegionalExperienceOperator,
  updateRegionalExperience,
  updateResultCount,
  updateSearchCriteria,
};
