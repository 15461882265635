/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import Loadable from 'react-loadable';
import Loading from '../layouts/loading';
import { Provider } from 'react-redux';

window.Routes = require('../routes.js.erb');

import configureStore from '../redux/store';
const reduxStore = configureStore();

// Layout loader
const Layout = (layout) => {
  return Loadable({
    loader: () => import('../layouts/' + layout),
    loading: Loading,
    delay: 300,
  });
};

// Switch layout based on route
class Application extends React.Component {
  render() {
    return (
      <Router>
        <Provider store={reduxStore}>
          <Switch>
            <Redirect exact path="/user" to="/user/login" />
            <Redirect from="/login" to="/user/login" />
            <Route path="/user/login" component={Layout('login')} />
            <Route path="/user" component={Layout('user')} />
            <Route path="/register" component={Layout('registration')} />
            <Route path="/loading" component={Layout('loading')} />
            <Route path="/error" component={Layout('error')} />
            <Route path="/expert" component={Layout('expert')} />
            <Route exact path="/" component={Layout('expert_login')} />
          </Switch>
        </Provider>
      </Router>
    );
  }
}

// Insert application to DOM
document.addEventListener('DOMContentLoaded', () => {
  document.body.innerHTML = '<div id="app"></div>';
  ReactDOM.render(
    React.createElement(Application, {}),
    document.getElementById('app'),
  );
});
