import React from 'react';
import ContentLoading from '../components/content_loading';

export class Base extends React.Component {
  static get BODY_ID() {
    return '';
  }

  static get requireUser() {
    return true;
  }

  static get requireTranslations() {
    return true;
  }

  constructor(props) {
    super(props);

    this.state = {
      translationsLoaded: !this.constructor.requireTranslations ? true : false,
      userLoaded: !this.constructor.requireUser ? true : false,
      bodyId: !this.constructor.requireUser
        ? this.constructor.BODY_ID
        : 'loading',
    };

    this.signOut = this.signOut.bind(this);
  }

  render() {
    if (
      this.state.translationsLoaded &&
      this.state.userLoaded &&
      !this.state.loading
    ) {
      return this.renderLayout();
    } else {
      return <ContentLoading />;
    }
  }

  componentDidMount() {
    this.setBodyId();

    if (!this.state.translationsLoaded) {
      this.loadTranslations();
    }

    if (!this.state.userLoaded) {
      this.loadUser();
    }
  }

  componentDidUpdate() {
    this.setBodyId();
  }

  setBodyId() {
    document.body.setAttribute('id', this.state.bodyId || 'loading');
  }

  loadUser() {
    jQuery.getJSON(Routes.login_user_path(), (result) => {
      User.current = result;
      this.setState({
        userLoaded: true,
        bodyId: this.constructor.BODY_ID,
      });
    });
  }

  loadTranslations() {
    jQuery.getJSON(Routes.translations_path(), (result) => {
      I18n.keys = result.keys;
      I18n.locale = result.locale;
      I18n.available_locales = result.available_locales;
      I18n.default_locale = result.default_locale;
      this.setState({
        translationsLoaded: true,
      });
    });
  }

  signOut(event, doneCallback) {
    this.setState({
      loading: true,
      bodyId: 'loading',
    });
    jQuery.getJSON(Routes.logout_path(), (result) => {
      if (typeof doneCallback == 'function') {
        doneCallback();
      }
      sessionStorage.clear();
      // Fresh start by doing a full page load
      window.location = result.path;
    });
    event.preventDefault();
  }
}

window.User = {
  current: {},

  hasPermission(permissionCode) {
    // check whether the user accept all user agreements or not.
    if (
      this.current.unaccepted_agreement_ids &&
      this.current.unaccepted_agreement_ids.length === 0 &&
      this.current.permissions
    ) {
      return this.current.permissions.indexOf(permissionCode) != -1;
    } else {
      return false;
    }
  },
};

window.I18n = {
  keys: {},
  locale: null,
  available_locales: [],
  default_locale: null,

  t: function (k, params) {
    let p = params || {};
    let translation = undefined;
    let keys = this.keys[p.locale || this.locale] || {};

    if (p.scope) {
      translation = (keys[p.scope][k] || '').slice(0); // clone
    } else if (typeof keys[k] == 'object') {
      return keys[k];
    } else {
      translation = (keys[k] || '').slice(0); // clone
    }

    if (translation.length == 0) {
      return k;
    }

    for (let paramKey in p) {
      if (paramKey != 'scope') {
        translation = translation.replace(
          new RegExp('%{' + paramKey + '}', 'ig'),
          p[paramKey],
        );
      }
    }
    return translation;
  },
};
