import { combineReducers } from 'redux';
import expertSearchReducer from './expert_search';
import expertProfileReducer from './expert_profile';

const appReducer = combineReducers({
  expertSearchReducer,
  expertProfileReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'CLEAR_DATA') {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
