import React from "react";
import { Base as BaseLayout } from "./base";
import ContentLoading from "../components/content_loading";

export default class Loading extends BaseLayout {
  static get BODY_ID() {
    return "loading";
  }

  static get requireUser() {
    return false;
  }

  static get requireTranslations() {
    return false;
  }

  renderLayout() {
    return (
      <div className="container">
        <ContentLoading />
      </div>
    );
  }
}
