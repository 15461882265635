import ACTIONS from '../actions/expert_profile';
import _ from 'lodash';

const defaultState = {
  currentPageIndex: 0,
  steps: [
    { id: 0, title: 'basic_information', isCompleted: false },
    { id: 1, title: 'documents', isCompleted: false },
    { id: 2, title: 'technical_expertise', isCompleted: false },
    { id: 3, title: 'regional_expertise', isCompleted: false },
    { id: 4, title: 'additional_information', isCompleted: false },
  ],
};

const expertProfileReducer = (state = defaultState, action) => {
  switch (action.type) {
    case ACTIONS.Types.UPDATE_CURRENT_PAGE_INDEX: {
      let newState = _.cloneDeep(state);
      newState.currentPageIndex = action.payload;
      newState.steps = newState.steps.map((s, i) => {
        return i > newState.currentPageIndex
          ? { ...s, isCompleted: false }
          : { ...s, isCompleted: true };
      });
      return newState;
    }
    default:
      return state;
  }
};
export default expertProfileReducer;
