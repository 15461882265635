/*
File generated by js-routes 1.4.11
Based on Rails 6.0.3.4 routes of FsbRoster::Application
 */

(function() {
  var DeprecatedGlobbingBehavior, NodeTypes, ParameterMissing, ReservedOptions, SpecialOptionsKey, UriEncoderSegmentRegex, Utils, result, root,
    hasProp = {}.hasOwnProperty,
    slice = [].slice;

  root = typeof exports !== "undefined" && exports !== null ? exports : this;

  ParameterMissing = function(message, fileName, lineNumber) {
    var instance;
    instance = new Error(message, fileName, lineNumber);
    if (Object.setPrototypeOf) {
      Object.setPrototypeOf(instance, Object.getPrototypeOf(this));
    } else {
      instance.__proto__ = this.__proto__;
    }
    if (Error.captureStackTrace) {
      Error.captureStackTrace(instance, ParameterMissing);
    }
    return instance;
  };

  ParameterMissing.prototype = Object.create(Error.prototype, {
    constructor: {
      value: Error,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });

  if (Object.setPrototypeOf) {
    Object.setPrototypeOf(ParameterMissing, Error);
  } else {
    ParameterMissing.__proto__ = Error;
  }

  NodeTypes = {"GROUP":1,"CAT":2,"SYMBOL":3,"OR":4,"STAR":5,"LITERAL":6,"SLASH":7,"DOT":8};

  DeprecatedGlobbingBehavior = false;

  SpecialOptionsKey = "_options";

  UriEncoderSegmentRegex = /[^a-zA-Z0-9\-\._~!\$&'\(\)\*\+,;=:@]/g;

  ReservedOptions = ['anchor', 'trailing_slash', 'subdomain', 'host', 'port', 'protocol'];

  Utils = {
    configuration: {
      prefix: "",
      default_url_options: {},
      special_options_key: "_options",
      serializer: null
    },
    default_serializer: function(object, prefix) {
      var element, i, j, key, len, prop, s;
      if (prefix == null) {
        prefix = null;
      }
      if (object == null) {
        return "";
      }
      if (!prefix && !(this.get_object_type(object) === "object")) {
        throw new Error("Url parameters should be a javascript hash");
      }
      s = [];
      switch (this.get_object_type(object)) {
        case "array":
          for (i = j = 0, len = object.length; j < len; i = ++j) {
            element = object[i];
            s.push(this.default_serializer(element, prefix + "[]"));
          }
          break;
        case "object":
          for (key in object) {
            if (!hasProp.call(object, key)) continue;
            prop = object[key];
            if ((prop == null) && (prefix != null)) {
              prop = "";
            }
            if (prop != null) {
              if (prefix != null) {
                key = prefix + "[" + key + "]";
              }
              s.push(this.default_serializer(prop, key));
            }
          }
          break;
        default:
          if (object != null) {
            s.push((encodeURIComponent(prefix.toString())) + "=" + (encodeURIComponent(object.toString())));
          }
      }
      if (!s.length) {
        return "";
      }
      return s.join("&");
    },
    serialize: function(object) {
      var custom_serializer;
      custom_serializer = this.configuration.serializer;
      if ((custom_serializer != null) && this.get_object_type(custom_serializer) === "function") {
        return custom_serializer(object);
      } else {
        return this.default_serializer(object);
      }
    },
    clean_path: function(path) {
      var last_index;
      path = path.split("://");
      last_index = path.length - 1;
      path[last_index] = path[last_index].replace(/\/+/g, "/");
      return path.join("://");
    },
    extract_options: function(number_of_params, args) {
      var last_el, options;
      last_el = args[args.length - 1];
      if ((args.length > number_of_params && last_el === void 0) || ((last_el != null) && "object" === this.get_object_type(last_el) && !this.looks_like_serialized_model(last_el))) {
        options = args.pop() || {};
        delete options[this.configuration.special_options_key];
        return options;
      } else {
        return {};
      }
    },
    looks_like_serialized_model: function(object) {
      return !object[this.configuration.special_options_key] && ("id" in object || "to_param" in object);
    },
    path_identifier: function(object) {
      var property;
      if (object === 0) {
        return "0";
      }
      if (!object) {
        return "";
      }
      property = object;
      if (this.get_object_type(object) === "object") {
        if ("to_param" in object) {
          if (object.to_param == null) {
            throw new ParameterMissing("Route parameter missing: to_param");
          }
          property = object.to_param;
        } else if ("id" in object) {
          if (object.id == null) {
            throw new ParameterMissing("Route parameter missing: id");
          }
          property = object.id;
        } else {
          property = object;
        }
        if (this.get_object_type(property) === "function") {
          property = property.call(object);
        }
      }
      return property.toString();
    },
    clone: function(obj) {
      var attr, copy, key;
      if ((obj == null) || "object" !== this.get_object_type(obj)) {
        return obj;
      }
      copy = obj.constructor();
      for (key in obj) {
        if (!hasProp.call(obj, key)) continue;
        attr = obj[key];
        copy[key] = attr;
      }
      return copy;
    },
    merge: function() {
      var tap, xs;
      xs = 1 <= arguments.length ? slice.call(arguments, 0) : [];
      tap = function(o, fn) {
        fn(o);
        return o;
      };
      if ((xs != null ? xs.length : void 0) > 0) {
        return tap({}, function(m) {
          var j, k, len, results, v, x;
          results = [];
          for (j = 0, len = xs.length; j < len; j++) {
            x = xs[j];
            results.push((function() {
              var results1;
              results1 = [];
              for (k in x) {
                v = x[k];
                results1.push(m[k] = v);
              }
              return results1;
            })());
          }
          return results;
        });
      }
    },
    normalize_options: function(parts, required_parts, default_options, actual_parameters) {
      var i, j, key, len, options, part, parts_options, result, route_parts, url_parameters, use_all_parts, value;
      options = this.extract_options(parts.length, actual_parameters);
      if (actual_parameters.length > parts.length) {
        throw new Error("Too many parameters provided for path");
      }
      use_all_parts = actual_parameters.length > required_parts.length;
      parts_options = {};
      for (key in options) {
        if (!hasProp.call(options, key)) continue;
        use_all_parts = true;
        if (this.indexOf(parts, key) >= 0) {
          parts_options[key] = value;
        }
      }
      options = this.merge(this.configuration.default_url_options, default_options, options);
      result = {};
      url_parameters = {};
      result['url_parameters'] = url_parameters;
      for (key in options) {
        if (!hasProp.call(options, key)) continue;
        value = options[key];
        if (this.indexOf(ReservedOptions, key) >= 0) {
          result[key] = value;
        } else {
          url_parameters[key] = value;
        }
      }
      route_parts = use_all_parts ? parts : required_parts;
      i = 0;
      for (j = 0, len = route_parts.length; j < len; j++) {
        part = route_parts[j];
        if (i < actual_parameters.length) {
          if (!parts_options.hasOwnProperty(part)) {
            url_parameters[part] = actual_parameters[i];
            ++i;
          }
        }
      }
      return result;
    },
    build_route: function(parts, required_parts, default_options, route, full_url, args) {
      var options, parameters, result, url, url_params;
      args = Array.prototype.slice.call(args);
      options = this.normalize_options(parts, required_parts, default_options, args);
      parameters = options['url_parameters'];
      result = "" + (this.get_prefix()) + (this.visit(route, parameters));
      url = Utils.clean_path(result);
      if (options['trailing_slash'] === true) {
        url = url.replace(/(.*?)[\/]?$/, "$1/");
      }
      if ((url_params = this.serialize(parameters)).length) {
        url += "?" + url_params;
      }
      url += options.anchor ? "#" + options.anchor : "";
      if (full_url) {
        url = this.route_url(options) + url;
      }
      return url;
    },
    visit: function(route, parameters, optional) {
      var left, left_part, right, right_part, type, value;
      if (optional == null) {
        optional = false;
      }
      type = route[0], left = route[1], right = route[2];
      switch (type) {
        case NodeTypes.GROUP:
          return this.visit(left, parameters, true);
        case NodeTypes.STAR:
          return this.visit_globbing(left, parameters, true);
        case NodeTypes.LITERAL:
        case NodeTypes.SLASH:
        case NodeTypes.DOT:
          return left;
        case NodeTypes.CAT:
          left_part = this.visit(left, parameters, optional);
          right_part = this.visit(right, parameters, optional);
          if (optional && ((this.is_optional_node(left[0]) && !left_part) || ((this.is_optional_node(right[0])) && !right_part))) {
            return "";
          }
          return "" + left_part + right_part;
        case NodeTypes.SYMBOL:
          value = parameters[left];
          delete parameters[left];
          if (value != null) {
            return this.encode_segment(this.path_identifier(value));
          }
          if (optional) {
            return "";
          } else {
            throw new ParameterMissing("Route parameter missing: " + left);
          }
          break;
        default:
          throw new Error("Unknown Rails node type");
      }
    },
    encode_segment: function(segment) {
      return segment.replace(UriEncoderSegmentRegex, function(str) {
        return encodeURIComponent(str);
      });
    },
    is_optional_node: function(node) {
      return this.indexOf([NodeTypes.STAR, NodeTypes.SYMBOL, NodeTypes.CAT], node) >= 0;
    },
    build_path_spec: function(route, wildcard) {
      var left, right, type;
      if (wildcard == null) {
        wildcard = false;
      }
      type = route[0], left = route[1], right = route[2];
      switch (type) {
        case NodeTypes.GROUP:
          return "(" + (this.build_path_spec(left)) + ")";
        case NodeTypes.CAT:
          return "" + (this.build_path_spec(left)) + (this.build_path_spec(right));
        case NodeTypes.STAR:
          return this.build_path_spec(left, true);
        case NodeTypes.SYMBOL:
          if (wildcard === true) {
            return "" + (left[0] === '*' ? '' : '*') + left;
          } else {
            return ":" + left;
          }
          break;
        case NodeTypes.SLASH:
        case NodeTypes.DOT:
        case NodeTypes.LITERAL:
          return left;
        default:
          throw new Error("Unknown Rails node type");
      }
    },
    visit_globbing: function(route, parameters, optional) {
      var left, right, type, value;
      type = route[0], left = route[1], right = route[2];
      value = parameters[left];
      delete parameters[left];
      if (value == null) {
        return this.visit(route, parameters, optional);
      }
      value = (function() {
        switch (this.get_object_type(value)) {
          case "array":
            return value.join("/");
          default:
            return value;
        }
      }).call(this);
      if (DeprecatedGlobbingBehavior) {
        return this.path_identifier(value);
      } else {
        return encodeURI(this.path_identifier(value));
      }
    },
    get_prefix: function() {
      var prefix;
      prefix = this.configuration.prefix;
      if (prefix !== "") {
        prefix = (prefix.match("/$") ? prefix : prefix + "/");
      }
      return prefix;
    },
    route: function(parts_table, default_options, route_spec, full_url) {
      var j, len, part, parts, path_fn, ref, required, required_parts;
      required_parts = [];
      parts = [];
      for (j = 0, len = parts_table.length; j < len; j++) {
        ref = parts_table[j], part = ref[0], required = ref[1];
        parts.push(part);
        if (required) {
          required_parts.push(part);
        }
      }
      path_fn = function() {
        return Utils.build_route(parts, required_parts, default_options, route_spec, full_url, arguments);
      };
      path_fn.required_params = required_parts;
      path_fn.toString = function() {
        return Utils.build_path_spec(route_spec);
      };
      return path_fn;
    },
    route_url: function(route_defaults) {
      var hostname, port, protocol, subdomain;
      if (typeof route_defaults === 'string') {
        return route_defaults;
      }
      hostname = route_defaults.host || Utils.current_host();
      if (!hostname) {
        return '';
      }
      subdomain = route_defaults.subdomain ? route_defaults.subdomain + '.' : '';
      protocol = route_defaults.protocol || Utils.current_protocol();
      port = route_defaults.port || (!route_defaults.host ? Utils.current_port() : void 0);
      port = port ? ":" + port : '';
      return protocol + "://" + subdomain + hostname + port;
    },
    has_location: function() {
      return (typeof window !== "undefined" && window !== null ? window.location : void 0) != null;
    },
    current_host: function() {
      if (this.has_location()) {
        return window.location.hostname;
      } else {
        return null;
      }
    },
    current_protocol: function() {
      if (this.has_location() && window.location.protocol !== '') {
        return window.location.protocol.replace(/:$/, '');
      } else {
        return 'http';
      }
    },
    current_port: function() {
      if (this.has_location() && window.location.port !== '') {
        return window.location.port;
      } else {
        return '';
      }
    },
    _classToTypeCache: null,
    _classToType: function() {
      var j, len, name, ref;
      if (this._classToTypeCache != null) {
        return this._classToTypeCache;
      }
      this._classToTypeCache = {};
      ref = "Boolean Number String Function Array Date RegExp Object Error".split(" ");
      for (j = 0, len = ref.length; j < len; j++) {
        name = ref[j];
        this._classToTypeCache["[object " + name + "]"] = name.toLowerCase();
      }
      return this._classToTypeCache;
    },
    get_object_type: function(obj) {
      if (root.jQuery && (root.jQuery.type != null)) {
        return root.jQuery.type(obj);
      }
      if (obj == null) {
        return "" + obj;
      }
      if (typeof obj === "object" || typeof obj === "function") {
        return this._classToType()[Object.prototype.toString.call(obj)] || "object";
      } else {
        return typeof obj;
      }
    },
    indexOf: function(array, element) {
      if (Array.prototype.indexOf) {
        return array.indexOf(element);
      } else {
        return this.indexOfImplementation(array, element);
      }
    },
    indexOfImplementation: function(array, element) {
      var el, i, j, len, result;
      result = -1;
      for (i = j = 0, len = array.length; j < len; i = ++j) {
        el = array[i];
        if (el === element) {
          result = i;
        }
      }
      return result;
    },
    namespace: function(root, namespace, routes) {
      var index, j, len, part, parts;
      parts = namespace ? namespace.split(".") : [];
      if (parts.length === 0) {
        return routes;
      }
      for (index = j = 0, len = parts.length; j < len; index = ++j) {
        part = parts[index];
        if (index < parts.length - 1) {
          root = (root[part] || (root[part] = {}));
        } else {
          return root[part] = routes;
        }
      }
    },
    configure: function(new_config) {
      return this.configuration = this.merge(this.configuration, new_config);
    },
    config: function() {
      return this.clone(this.configuration);
    },
    make: function() {
      var routes;
      routes = {
// blood_type_create => /blood_type/create(.:format)
  // function(options)
  blood_type_create_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"blood_type",false],[2,[7,"/",false],[2,[6,"create",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// blood_type_delete => /blood_type/delete/:blood_type_id(.:format)
  // function(blood_type_id, options)
  blood_type_delete_path: Utils.route([["blood_type_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"blood_type",false],[2,[7,"/",false],[2,[6,"delete",false],[2,[7,"/",false],[2,[3,"blood_type_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// blood_type_get_form_data => /blood_type/get_form_data(/:blood_type_id)(.:format)
  // function(options)
  blood_type_get_form_data_path: Utils.route([["blood_type_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"blood_type",false],[2,[7,"/",false],[2,[6,"get_form_data",false],[2,[1,[2,[7,"/",false],[3,"blood_type_id",false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// blood_type_list => /blood_type/list(.:format)
  // function(options)
  blood_type_list_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"blood_type",false],[2,[7,"/",false],[2,[6,"list",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// blood_type_update => /blood_type/update/:blood_type_id(.:format)
  // function(blood_type_id, options)
  blood_type_update_path: Utils.route([["blood_type_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"blood_type",false],[2,[7,"/",false],[2,[6,"update",false],[2,[7,"/",false],[2,[3,"blood_type_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// dashboard_data => /dashboard/dashboard_data(.:format)
  // function(options)
  dashboard_data_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"dashboard",false],[2,[7,"/",false],[2,[6,"dashboard_data",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// donor_organization_create => /donor_organization/create(.:format)
  // function(options)
  donor_organization_create_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"donor_organization",false],[2,[7,"/",false],[2,[6,"create",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// donor_organization_delete => /donor_organization/delete/:donor_organization_id(.:format)
  // function(donor_organization_id, options)
  donor_organization_delete_path: Utils.route([["donor_organization_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"donor_organization",false],[2,[7,"/",false],[2,[6,"delete",false],[2,[7,"/",false],[2,[3,"donor_organization_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// donor_organization_get_form_data => /donor_organization/get_form_data(/:donor_organization_id)(.:format)
  // function(options)
  donor_organization_get_form_data_path: Utils.route([["donor_organization_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"donor_organization",false],[2,[7,"/",false],[2,[6,"get_form_data",false],[2,[1,[2,[7,"/",false],[3,"donor_organization_id",false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// donor_organization_list => /donor_organization/list(.:format)
  // function(options)
  donor_organization_list_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"donor_organization",false],[2,[7,"/",false],[2,[6,"list",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// donor_organization_update => /donor_organization/update/:donor_organization_id(.:format)
  // function(donor_organization_id, options)
  donor_organization_update_path: Utils.route([["donor_organization_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"donor_organization",false],[2,[7,"/",false],[2,[6,"update",false],[2,[7,"/",false],[2,[3,"donor_organization_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// driving_licence_create => /driving_licence/create(.:format)
  // function(options)
  driving_licence_create_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"driving_licence",false],[2,[7,"/",false],[2,[6,"create",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// driving_licence_delete => /driving_licence/delete/:driving_licence_id(.:format)
  // function(driving_licence_id, options)
  driving_licence_delete_path: Utils.route([["driving_licence_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"driving_licence",false],[2,[7,"/",false],[2,[6,"delete",false],[2,[7,"/",false],[2,[3,"driving_licence_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// driving_licence_get_form_data => /driving_licence/get_form_data(/:driving_licence_id)(.:format)
  // function(options)
  driving_licence_get_form_data_path: Utils.route([["driving_licence_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"driving_licence",false],[2,[7,"/",false],[2,[6,"get_form_data",false],[2,[1,[2,[7,"/",false],[3,"driving_licence_id",false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// driving_licence_list => /driving_licence/list(.:format)
  // function(options)
  driving_licence_list_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"driving_licence",false],[2,[7,"/",false],[2,[6,"list",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// driving_licence_update => /driving_licence/update/:driving_licence_id(.:format)
  // function(driving_licence_id, options)
  driving_licence_update_path: Utils.route([["driving_licence_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"driving_licence",false],[2,[7,"/",false],[2,[6,"update",false],[2,[7,"/",false],[2,[3,"driving_licence_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_rails_conductor_inbound_email => /rails/conductor/action_mailbox/inbound_emails/:id/edit(.:format)
  // function(id, options)
  edit_rails_conductor_inbound_email_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// education_create => /education/create(.:format)
  // function(options)
  education_create_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"education",false],[2,[7,"/",false],[2,[6,"create",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// education_delete => /education/delete/:education_id(.:format)
  // function(education_id, options)
  education_delete_path: Utils.route([["education_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"education",false],[2,[7,"/",false],[2,[6,"delete",false],[2,[7,"/",false],[2,[3,"education_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// education_get_form_data => /education/get_form_data(/:education_id)(.:format)
  // function(options)
  education_get_form_data_path: Utils.route([["education_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"education",false],[2,[7,"/",false],[2,[6,"get_form_data",false],[2,[1,[2,[7,"/",false],[3,"education_id",false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// education_list => /education/list(.:format)
  // function(options)
  education_list_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"education",false],[2,[7,"/",false],[2,[6,"list",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// education_update => /education/update/:education_id(.:format)
  // function(education_id, options)
  education_update_path: Utils.route([["education_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"education",false],[2,[7,"/",false],[2,[6,"update",false],[2,[7,"/",false],[2,[3,"education_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// email_login => /email_login/:otp(.:format)
  // function(otp, options)
  email_login_path: Utils.route([["otp",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"email_login",false],[2,[7,"/",false],[2,[3,"otp",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// employee_create => /employee/create(.:format)
  // function(options)
  employee_create_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"employee",false],[2,[7,"/",false],[2,[6,"create",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// employee_delete => /employee/delete/:employee_id(.:format)
  // function(employee_id, options)
  employee_delete_path: Utils.route([["employee_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employee",false],[2,[7,"/",false],[2,[6,"delete",false],[2,[7,"/",false],[2,[3,"employee_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// employee_get => /employee/get/:employee_id(.:format)
  // function(employee_id, options)
  employee_get_path: Utils.route([["employee_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employee",false],[2,[7,"/",false],[2,[6,"get",false],[2,[7,"/",false],[2,[3,"employee_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// employee_get_from_data => /employee/get_form_data(/:employee_id)(.:format)
  // function(options)
  employee_get_from_data_path: Utils.route([["employee_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"employee",false],[2,[7,"/",false],[2,[6,"get_form_data",false],[2,[1,[2,[7,"/",false],[3,"employee_id",false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// employee_list => /employee/list(.:format)
  // function(options)
  employee_list_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"employee",false],[2,[7,"/",false],[2,[6,"list",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// employee_list_filters => /employee/list_filters(.:format)
  // function(options)
  employee_list_filters_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"employee",false],[2,[7,"/",false],[2,[6,"list_filters",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// employee_update => /employee/update/:employee_id(.:format)
  // function(employee_id, options)
  employee_update_path: Utils.route([["employee_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employee",false],[2,[7,"/",false],[2,[6,"update",false],[2,[7,"/",false],[2,[3,"employee_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// experience_year_create => /experience_year/create(.:format)
  // function(options)
  experience_year_create_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"experience_year",false],[2,[7,"/",false],[2,[6,"create",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// experience_year_delete => /experience_year/delete/:experience_year_id(.:format)
  // function(experience_year_id, options)
  experience_year_delete_path: Utils.route([["experience_year_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"experience_year",false],[2,[7,"/",false],[2,[6,"delete",false],[2,[7,"/",false],[2,[3,"experience_year_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// experience_year_get_form_data => /experience_year/get_form_data(/:experience_year_id)(.:format)
  // function(options)
  experience_year_get_form_data_path: Utils.route([["experience_year_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"experience_year",false],[2,[7,"/",false],[2,[6,"get_form_data",false],[2,[1,[2,[7,"/",false],[3,"experience_year_id",false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// experience_year_list => /experience_year/list(.:format)
  // function(options)
  experience_year_list_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"experience_year",false],[2,[7,"/",false],[2,[6,"list",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// experience_year_update => /experience_year/update/:experience_year_id(.:format)
  // function(experience_year_id, options)
  experience_year_update_path: Utils.route([["experience_year_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"experience_year",false],[2,[7,"/",false],[2,[6,"update",false],[2,[7,"/",false],[2,[3,"experience_year_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// expert_confirm_delete => /profile/confirm_delete(.:format)
  // function(options)
  expert_confirm_delete_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"profile",false],[2,[7,"/",false],[2,[6,"confirm_delete",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// expert_create => /register/submit(.:format)
  // function(options)
  expert_create_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"register",false],[2,[7,"/",false],[2,[6,"submit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// expert_create_external => /expert/create_external/:post_id(.:format)
  // function(post_id, options)
  expert_create_external_path: Utils.route([["post_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"expert",false],[2,[7,"/",false],[2,[6,"create_external",false],[2,[7,"/",false],[2,[3,"post_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// expert_cv_template_download => /expert/cv/template(.:format)
  // function(options)
  expert_cv_template_download_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"expert",false],[2,[7,"/",false],[2,[6,"cv",false],[2,[7,"/",false],[2,[6,"template",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// expert_delete => /expert/delete/:expert_id(.:format)
  // function(expert_id, options)
  expert_delete_path: Utils.route([["expert_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"expert",false],[2,[7,"/",false],[2,[6,"delete",false],[2,[7,"/",false],[2,[3,"expert_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// expert_delete_cv => /expert/cv/:filename/delete(/:expert_id)(.:format)
  // function(filename, options)
  expert_delete_cv_path: Utils.route([["filename",true],["expert_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"expert",false],[2,[7,"/",false],[2,[6,"cv",false],[2,[7,"/",false],[2,[3,"filename",false],[2,[7,"/",false],[2,[6,"delete",false],[2,[1,[2,[7,"/",false],[3,"expert_id",false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// expert_delete_modified_cv => /expert/modified_cv/:filename/delete/:expert_id(.:format)
  // function(filename, expert_id, options)
  expert_delete_modified_cv_path: Utils.route([["filename",true],["expert_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"expert",false],[2,[7,"/",false],[2,[6,"modified_cv",false],[2,[7,"/",false],[2,[3,"filename",false],[2,[7,"/",false],[2,[6,"delete",false],[2,[7,"/",false],[2,[3,"expert_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// expert_delete_self => /profile/delete(.:format)
  // function(options)
  expert_delete_self_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"profile",false],[2,[7,"/",false],[2,[6,"delete",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// expert_download_cv_zip => /expert/download_cv_zip(.:format)
  // function(options)
  expert_download_cv_zip_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"expert",false],[2,[7,"/",false],[2,[6,"download_cv_zip",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// expert_download_document => /expert/:expert_id/download_document/type/:type/:document_id(.:format)
  // function(expert_id, type, document_id, options)
  expert_download_document_path: Utils.route([["expert_id",true],["type",true],["document_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"expert",false],[2,[7,"/",false],[2,[3,"expert_id",false],[2,[7,"/",false],[2,[6,"download_document",false],[2,[7,"/",false],[2,[6,"type",false],[2,[7,"/",false],[2,[3,"type",false],[2,[7,"/",false],[2,[3,"document_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// expert_download_profile_picture => /expert/download_profile_picture(/:size)(.:format)
  // function(options)
  expert_download_profile_picture_path: Utils.route([["size",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"expert",false],[2,[7,"/",false],[2,[6,"download_profile_picture",false],[2,[1,[2,[7,"/",false],[3,"size",false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// expert_edit => /expert/edit/:section(/:expert_id)(.:format)
  // function(section, options)
  expert_edit_path: Utils.route([["section",true],["expert_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"expert",false],[2,[7,"/",false],[2,[6,"edit",false],[2,[7,"/",false],[2,[3,"section",false],[2,[1,[2,[7,"/",false],[3,"expert_id",false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// expert_get => /expert/get/:expert_id(.:format)
  // function(expert_id, options)
  expert_get_path: Utils.route([["expert_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"expert",false],[2,[7,"/",false],[2,[6,"get",false],[2,[7,"/",false],[2,[3,"expert_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// expert_get_form_data => /register/expert_get_form_data(/:expert_id)(.:format)
  // function(options)
  expert_get_form_data_path: Utils.route([["expert_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"register",false],[2,[7,"/",false],[2,[6,"expert_get_form_data",false],[2,[1,[2,[7,"/",false],[3,"expert_id",false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// expert_list_current_jobs => /expert/list_current_jobs(.:format)
  // function(options)
  expert_list_current_jobs_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"expert",false],[2,[7,"/",false],[2,[6,"list_current_jobs",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// expert_list_historical_jobs => /expert/list_historical_jobs(.:format)
  // function(options)
  expert_list_historical_jobs_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"expert",false],[2,[7,"/",false],[2,[6,"list_historical_jobs",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// expert_new_external => /expert/new_external/:post_id(.:format)
  // function(post_id, options)
  expert_new_external_path: Utils.route([["post_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"expert",false],[2,[7,"/",false],[2,[6,"new_external",false],[2,[7,"/",false],[2,[3,"post_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// expert_open_cv => /expert/cv/:filename/open(/:expert_id)(.:format)
  // function(filename, options)
  expert_open_cv_path: Utils.route([["filename",true],["expert_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"expert",false],[2,[7,"/",false],[2,[6,"cv",false],[2,[7,"/",false],[2,[3,"filename",false],[2,[7,"/",false],[2,[6,"open",false],[2,[1,[2,[7,"/",false],[3,"expert_id",false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// expert_open_modified_cv => /expert/modified_cv/:filename/open(/:expert_id)(.:format)
  // function(filename, options)
  expert_open_modified_cv_path: Utils.route([["filename",true],["expert_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"expert",false],[2,[7,"/",false],[2,[6,"modified_cv",false],[2,[7,"/",false],[2,[3,"filename",false],[2,[7,"/",false],[2,[6,"open",false],[2,[1,[2,[7,"/",false],[3,"expert_id",false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// expert_profile_deleted => /profile/deleted(.:format)
  // function(options)
  expert_profile_deleted_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"profile",false],[2,[7,"/",false],[2,[6,"deleted",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// expert_profile_progress => /expert/profile/progress(/:expert_id)(.:format)
  // function(options)
  expert_profile_progress_path: Utils.route([["expert_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"expert",false],[2,[7,"/",false],[2,[6,"profile",false],[2,[7,"/",false],[2,[6,"progress",false],[2,[1,[2,[7,"/",false],[3,"expert_id",false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// expert_registration_status => /expert/:expert_id/registraion_status(.:format)
  // function(expert_id, options)
  expert_registration_status_path: Utils.route([["expert_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"expert",false],[2,[7,"/",false],[2,[3,"expert_id",false],[2,[7,"/",false],[2,[6,"registraion_status",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// expert_search_count => /expert/search/count(.:format)
  // function(options)
  expert_search_count_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"expert",false],[2,[7,"/",false],[2,[6,"search",false],[2,[7,"/",false],[2,[6,"count",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// expert_search_form_data => /expert/search/form_data(.:format)
  // function(options)
  expert_search_form_data_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"expert",false],[2,[7,"/",false],[2,[6,"search",false],[2,[7,"/",false],[2,[6,"form_data",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// expert_search_results => /expert/search/results(.:format)
  // function(options)
  expert_search_results_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"expert",false],[2,[7,"/",false],[2,[6,"search",false],[2,[7,"/",false],[2,[6,"results",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// expert_search_results_excel => /expert/search_results_excel(.:format)
  // function(options)
  expert_search_results_excel_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"expert",false],[2,[7,"/",false],[2,[6,"search_results_excel",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// expert_show => /expert/show/:expert_id(/:from)(.:format)
  // function(expert_id, options)
  expert_show_path: Utils.route([["expert_id",true],["from",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"expert",false],[2,[7,"/",false],[2,[6,"show",false],[2,[7,"/",false],[2,[3,"expert_id",false],[2,[1,[2,[7,"/",false],[3,"from",false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// expert_show_section => /expert/section/:section(/:expert_id)(.:format)
  // function(section, options)
  expert_show_section_path: Utils.route([["section",true],["expert_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"expert",false],[2,[7,"/",false],[2,[6,"section",false],[2,[7,"/",false],[2,[3,"section",false],[2,[1,[2,[7,"/",false],[3,"expert_id",false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// expert_update => /expert/update/:section(/:expert_id)(.:format)
  // function(section, options)
  expert_update_path: Utils.route([["section",true],["expert_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"expert",false],[2,[7,"/",false],[2,[6,"update",false],[2,[7,"/",false],[2,[3,"section",false],[2,[1,[2,[7,"/",false],[3,"expert_id",false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// expert_upload_document => /expert/upload_document/:upload_id(.:format)
  // function(upload_id, options)
  expert_upload_document_path: Utils.route([["upload_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"expert",false],[2,[7,"/",false],[2,[6,"upload_document",false],[2,[7,"/",false],[2,[3,"upload_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// expert_upload_profile_picture => /expert/upload_profile_picture(.:format)
  // function(options)
  expert_upload_profile_picture_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"expert",false],[2,[7,"/",false],[2,[6,"upload_profile_picture",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// expert_verify => /expert/verify/:expert_id(.:format)
  // function(expert_id, options)
  expert_verify_path: Utils.route([["expert_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"expert",false],[2,[7,"/",false],[2,[6,"verify",false],[2,[7,"/",false],[2,[3,"expert_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// expertise_type_create => /expertise_type/create(.:format)
  // function(options)
  expertise_type_create_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"expertise_type",false],[2,[7,"/",false],[2,[6,"create",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// expertise_type_delete => /expertise_type/delete/:expertise_type_id(.:format)
  // function(expertise_type_id, options)
  expertise_type_delete_path: Utils.route([["expertise_type_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"expertise_type",false],[2,[7,"/",false],[2,[6,"delete",false],[2,[7,"/",false],[2,[3,"expertise_type_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// expertise_type_get_form_data => /expertise_type/get_form_data(/:expertise_type_id)(.:format)
  // function(options)
  expertise_type_get_form_data_path: Utils.route([["expertise_type_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"expertise_type",false],[2,[7,"/",false],[2,[6,"get_form_data",false],[2,[1,[2,[7,"/",false],[3,"expertise_type_id",false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// expertise_type_list => /expertise_type/list(.:format)
  // function(options)
  expertise_type_list_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"expertise_type",false],[2,[7,"/",false],[2,[6,"list",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// expertise_type_update => /expertise_type/update/:expertise_type_id(.:format)
  // function(expertise_type_id, options)
  expertise_type_update_path: Utils.route([["expertise_type_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"expertise_type",false],[2,[7,"/",false],[2,[6,"update",false],[2,[7,"/",false],[2,[3,"expertise_type_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// expiring_document_type_create => /expiring_document_type/create(.:format)
  // function(options)
  expiring_document_type_create_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"expiring_document_type",false],[2,[7,"/",false],[2,[6,"create",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// expiring_document_type_delete => /expiring_document_type/delete/:expiring_document_type_id(.:format)
  // function(expiring_document_type_id, options)
  expiring_document_type_delete_path: Utils.route([["expiring_document_type_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"expiring_document_type",false],[2,[7,"/",false],[2,[6,"delete",false],[2,[7,"/",false],[2,[3,"expiring_document_type_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// expiring_document_type_get_form_data => /expiring_document_type/get_form_data(/:expiring_document_type_id)(.:format)
  // function(options)
  expiring_document_type_get_form_data_path: Utils.route([["expiring_document_type_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"expiring_document_type",false],[2,[7,"/",false],[2,[6,"get_form_data",false],[2,[1,[2,[7,"/",false],[3,"expiring_document_type_id",false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// expiring_document_type_list => /expiring_document_type/list(.:format)
  // function(options)
  expiring_document_type_list_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"expiring_document_type",false],[2,[7,"/",false],[2,[6,"list",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// expiring_document_type_update => /expiring_document_type/update/:expiring_document_type_id(.:format)
  // function(expiring_document_type_id, options)
  expiring_document_type_update_path: Utils.route([["expiring_document_type_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"expiring_document_type",false],[2,[7,"/",false],[2,[6,"update",false],[2,[7,"/",false],[2,[3,"expiring_document_type_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// file_download => /file/download/:file_id(.:format)
  // function(file_id, options)
  file_download_path: Utils.route([["file_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"file",false],[2,[7,"/",false],[2,[6,"download",false],[2,[7,"/",false],[2,[3,"file_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// fsb_course_create => /fsb_course/create(.:format)
  // function(options)
  fsb_course_create_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"fsb_course",false],[2,[7,"/",false],[2,[6,"create",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// fsb_course_delete => /fsb_course/delete/:fsb_course_id(.:format)
  // function(fsb_course_id, options)
  fsb_course_delete_path: Utils.route([["fsb_course_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"fsb_course",false],[2,[7,"/",false],[2,[6,"delete",false],[2,[7,"/",false],[2,[3,"fsb_course_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// fsb_course_get_form_data => /fsb_course/get_form_data(/:fsb_course_id)(.:format)
  // function(options)
  fsb_course_get_form_data_path: Utils.route([["fsb_course_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"fsb_course",false],[2,[7,"/",false],[2,[6,"get_form_data",false],[2,[1,[2,[7,"/",false],[3,"fsb_course_id",false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// fsb_course_list => /fsb_course/list(.:format)
  // function(options)
  fsb_course_list_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"fsb_course",false],[2,[7,"/",false],[2,[6,"list",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// fsb_course_update => /fsb_course/update/:fsb_course_id(.:format)
  // function(fsb_course_id, options)
  fsb_course_update_path: Utils.route([["fsb_course_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"fsb_course",false],[2,[7,"/",false],[2,[6,"update",false],[2,[7,"/",false],[2,[3,"fsb_course_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// job_add_candidates => /job(/:job_id)/add_candidates(.:format)
  // function(options)
  job_add_candidates_path: Utils.route([["job_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"job",false],[2,[1,[2,[7,"/",false],[3,"job_id",false]],false],[2,[7,"/",false],[2,[6,"add_candidates",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// job_add_external_candidate => /job/:job_id/add_external_candidate(.:format)
  // function(job_id, options)
  job_add_external_candidate_path: Utils.route([["job_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"job",false],[2,[7,"/",false],[2,[3,"job_id",false],[2,[7,"/",false],[2,[6,"add_external_candidate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// job_apply => /job/:job_id/apply(.:format)
  // function(job_id, options)
  job_apply_path: Utils.route([["job_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"job",false],[2,[7,"/",false],[2,[3,"job_id",false],[2,[7,"/",false],[2,[6,"apply",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// job_candidates_excel => /job/:job_id/candidates_excel(.:format)
  // function(job_id, options)
  job_candidates_excel_path: Utils.route([["job_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"job",false],[2,[7,"/",false],[2,[3,"job_id",false],[2,[7,"/",false],[2,[6,"candidates_excel",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// job_candidates_list_filters => /job/:job_id/candidates/list_filters(.:format)
  // function(job_id, options)
  job_candidates_list_filters_path: Utils.route([["job_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"job",false],[2,[7,"/",false],[2,[3,"job_id",false],[2,[7,"/",false],[2,[6,"candidates",false],[2,[7,"/",false],[2,[6,"list_filters",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// job_create => /job/create(.:format)
  // function(options)
  job_create_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"job",false],[2,[7,"/",false],[2,[6,"create",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// job_delete => /job/delete/:job_id(.:format)
  // function(job_id, options)
  job_delete_path: Utils.route([["job_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"job",false],[2,[7,"/",false],[2,[6,"delete",false],[2,[7,"/",false],[2,[3,"job_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// job_delete_candidates => /job/:job_id/delete_candidates(.:format)
  // function(job_id, options)
  job_delete_candidates_path: Utils.route([["job_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"job",false],[2,[7,"/",false],[2,[3,"job_id",false],[2,[7,"/",false],[2,[6,"delete_candidates",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// job_download_file => /job/download_file/:file_id(.:format)
  // function(file_id, options)
  job_download_file_path: Utils.route([["file_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"job",false],[2,[7,"/",false],[2,[6,"download_file",false],[2,[7,"/",false],[2,[3,"file_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// job_get => /job/get/:job_id(.:format)
  // function(job_id, options)
  job_get_path: Utils.route([["job_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"job",false],[2,[7,"/",false],[2,[6,"get",false],[2,[7,"/",false],[2,[3,"job_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// job_get_form_data => /job/get_form_data(/:job_id)(.:format)
  // function(options)
  job_get_form_data_path: Utils.route([["job_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"job",false],[2,[7,"/",false],[2,[6,"get_form_data",false],[2,[1,[2,[7,"/",false],[3,"job_id",false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// job_get_job_apply_form_data => /job/:job_id/get_job_apply_form_data(.:format)
  // function(job_id, options)
  job_get_job_apply_form_data_path: Utils.route([["job_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"job",false],[2,[7,"/",false],[2,[3,"job_id",false],[2,[7,"/",false],[2,[6,"get_job_apply_form_data",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// job_get_project_subtypes => /jobs/subtypes/:project_type(.:format)
  // function(project_type, options)
  job_get_project_subtypes_path: Utils.route([["project_type",true],["format",false]], {"project_type":null}, [2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[6,"subtypes",false],[2,[7,"/",false],[2,[3,"project_type",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// job_get_subtype_projects => /jobs/subtype_projects/:project_subtype_id(.:format)
  // function(project_subtype_id, options)
  job_get_subtype_projects_path: Utils.route([["project_subtype_id",true],["format",false]], {"project_subtype_id":null}, [2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[6,"subtype_projects",false],[2,[7,"/",false],[2,[3,"project_subtype_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// job_list => /job/list(.:format)
  // function(options)
  job_list_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"job",false],[2,[7,"/",false],[2,[6,"list",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// job_list_candidates => /job/:job_id/list_candidates(.:format)
  // function(job_id, options)
  job_list_candidates_path: Utils.route([["job_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"job",false],[2,[7,"/",false],[2,[3,"job_id",false],[2,[7,"/",false],[2,[6,"list_candidates",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// job_list_filters => /job/job_filters(.:format)
  // function(options)
  job_list_filters_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"job",false],[2,[7,"/",false],[2,[6,"job_filters",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// job_ranking_form_options => /job/ranking_form_options(.:format)
  // function(options)
  job_ranking_form_options_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"job",false],[2,[7,"/",false],[2,[6,"ranking_form_options",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// job_update => /job/update/:job_id(.:format)
  // function(job_id, options)
  job_update_path: Utils.route([["job_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"job",false],[2,[7,"/",false],[2,[6,"update",false],[2,[7,"/",false],[2,[3,"job_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// job_update_candidate_interview_date => /job/:job_id/update_candidate_interview_date(.:format)
  // function(job_id, options)
  job_update_candidate_interview_date_path: Utils.route([["job_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"job",false],[2,[7,"/",false],[2,[3,"job_id",false],[2,[7,"/",false],[2,[6,"update_candidate_interview_date",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// job_update_candidate_note => /job_candidate/:expert_id/update_note(.:format)
  // function(expert_id, options)
  job_update_candidate_note_path: Utils.route([["expert_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"job_candidate",false],[2,[7,"/",false],[2,[3,"expert_id",false],[2,[7,"/",false],[2,[6,"update_note",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// job_update_candidate_ranking => /job/:job_id/update_candidate_ranking(.:format)
  // function(job_id, options)
  job_update_candidate_ranking_path: Utils.route([["job_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"job",false],[2,[7,"/",false],[2,[3,"job_id",false],[2,[7,"/",false],[2,[6,"update_candidate_ranking",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// job_upload_file => /job/upload_file/:upload_id(.:format)
  // function(upload_id, options)
  job_upload_file_path: Utils.route([["upload_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"job",false],[2,[7,"/",false],[2,[6,"upload_file",false],[2,[7,"/",false],[2,[3,"upload_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// language_skill_option_create => /language_skill_option/create(.:format)
  // function(options)
  language_skill_option_create_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"language_skill_option",false],[2,[7,"/",false],[2,[6,"create",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// language_skill_option_delete => /language_skill_option/delete/:language_skill_option_id(.:format)
  // function(language_skill_option_id, options)
  language_skill_option_delete_path: Utils.route([["language_skill_option_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"language_skill_option",false],[2,[7,"/",false],[2,[6,"delete",false],[2,[7,"/",false],[2,[3,"language_skill_option_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// language_skill_option_get_form_data => /language_skill_option/get_form_data(/:language_skill_option_id)(.:format)
  // function(options)
  language_skill_option_get_form_data_path: Utils.route([["language_skill_option_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"language_skill_option",false],[2,[7,"/",false],[2,[6,"get_form_data",false],[2,[1,[2,[7,"/",false],[3,"language_skill_option_id",false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// language_skill_option_list => /language_skill_option/list(.:format)
  // function(options)
  language_skill_option_list_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"language_skill_option",false],[2,[7,"/",false],[2,[6,"list",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// language_skill_option_update => /language_skill_option/update/:language_skill_option_id(.:format)
  // function(language_skill_option_id, options)
  language_skill_option_update_path: Utils.route([["language_skill_option_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"language_skill_option",false],[2,[7,"/",false],[2,[6,"update",false],[2,[7,"/",false],[2,[3,"language_skill_option_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// login => /login(.:format)
  // function(options)
  login_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"login",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// login_change_password => /login/change_password(.:format)
  // function(options)
  login_change_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"login",false],[2,[7,"/",false],[2,[6,"change_password",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// login_change_password_token => /login/change_password_token(.:format)
  // function(options)
  login_change_password_token_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"login",false],[2,[7,"/",false],[2,[6,"change_password_token",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// login_registration_token => /login/send_registration_token(.:format)
  // function(options)
  login_registration_token_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"login",false],[2,[7,"/",false],[2,[6,"send_registration_token",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// login_request_password_change => /login/request_password_change(.:format)
  // function(options)
  login_request_password_change_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"login",false],[2,[7,"/",false],[2,[6,"request_password_change",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// login_token_login => /login/token_login(.:format)
  // function(options)
  login_token_login_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"login",false],[2,[7,"/",false],[2,[6,"token_login",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// login_user => /login/user(.:format)
  // function(options)
  login_user_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"login",false],[2,[7,"/",false],[2,[6,"user",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// logout => /logout(.:format)
  // function(options)
  logout_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"logout",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// nationality_create => /nationality/create(.:format)
  // function(options)
  nationality_create_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"nationality",false],[2,[7,"/",false],[2,[6,"create",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// nationality_delete => /nationality/delete/:nationality_id(.:format)
  // function(nationality_id, options)
  nationality_delete_path: Utils.route([["nationality_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"nationality",false],[2,[7,"/",false],[2,[6,"delete",false],[2,[7,"/",false],[2,[3,"nationality_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// nationality_get_form_data => /nationality/get_form_data(/:nationality_id)(.:format)
  // function(options)
  nationality_get_form_data_path: Utils.route([["nationality_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"nationality",false],[2,[7,"/",false],[2,[6,"get_form_data",false],[2,[1,[2,[7,"/",false],[3,"nationality_id",false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// nationality_list => /nationality/list(.:format)
  // function(options)
  nationality_list_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"nationality",false],[2,[7,"/",false],[2,[6,"list",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// nationality_update => /nationality/update/:nationality_id(.:format)
  // function(nationality_id, options)
  nationality_update_path: Utils.route([["nationality_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"nationality",false],[2,[7,"/",false],[2,[6,"update",false],[2,[7,"/",false],[2,[3,"nationality_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_rails_conductor_inbound_email => /rails/conductor/action_mailbox/inbound_emails/new(.:format)
  // function(options)
  new_rails_conductor_inbound_email_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// newsletter_recipients_expert_excel => /expert/export_newsletter_recipients_expert_excel(.:format)
  // function(options)
  newsletter_recipients_expert_excel_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"expert",false],[2,[7,"/",false],[2,[6,"export_newsletter_recipients_expert_excel",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// password_change => /user/login/psw/:token(.:format)
  // function(token, options)
  password_change_path: Utils.route([["token",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"user",false],[2,[7,"/",false],[2,[6,"login",false],[2,[7,"/",false],[2,[6,"psw",false],[2,[7,"/",false],[2,[3,"token",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// password_complexity_get_form_data => /password_complexity_setting/get_form_data(/:id)(.:format)
  // function(options)
  password_complexity_get_form_data_path: Utils.route([["id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"password_complexity_setting",false],[2,[7,"/",false],[2,[6,"get_form_data",false],[2,[1,[2,[7,"/",false],[3,"id",false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// password_complexity_update => /password_complexity_setting/update(/:id)(.:format)
  // function(options)
  password_complexity_update_path: Utils.route([["id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"password_complexity_setting",false],[2,[7,"/",false],[2,[6,"update",false],[2,[1,[2,[7,"/",false],[3,"id",false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// project__within_date_range_report => /project/generate_within_date_range_report(.:format)
  // function(options)
  project__within_date_range_report_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"project",false],[2,[7,"/",false],[2,[6,"generate_within_date_range_report",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// project_add_experts => /project(/:project_id)/jobs/:job_id/add_project_experts(.:format)
  // function(job_id, options)
  project_add_experts_path: Utils.route([["project_id",false],["job_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"project",false],[2,[1,[2,[7,"/",false],[3,"project_id",false]],false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"job_id",false],[2,[7,"/",false],[2,[6,"add_project_experts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// project_add_extension => /project/:project_id/add_extension(.:format)
  // function(project_id, options)
  project_add_extension_path: Utils.route([["project_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"project",false],[2,[7,"/",false],[2,[3,"project_id",false],[2,[7,"/",false],[2,[6,"add_extension",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// project_create => /project/create(.:format)
  // function(options)
  project_create_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"project",false],[2,[7,"/",false],[2,[6,"create",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// project_create_file => /project/:project_id/create_file(.:format)
  // function(project_id, options)
  project_create_file_path: Utils.route([["project_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"project",false],[2,[7,"/",false],[2,[3,"project_id",false],[2,[7,"/",false],[2,[6,"create_file",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// project_delete => /project/delete/:project_id(.:format)
  // function(project_id, options)
  project_delete_path: Utils.route([["project_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"project",false],[2,[7,"/",false],[2,[6,"delete",false],[2,[7,"/",false],[2,[3,"project_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// project_delete_extension => /project/:project_id/delete_extension/:extension_id(.:format)
  // function(project_id, extension_id, options)
  project_delete_extension_path: Utils.route([["project_id",true],["extension_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"project",false],[2,[7,"/",false],[2,[3,"project_id",false],[2,[7,"/",false],[2,[6,"delete_extension",false],[2,[7,"/",false],[2,[3,"extension_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// project_delete_file => /project/delete_file/:file_id(.:format)
  // function(file_id, options)
  project_delete_file_path: Utils.route([["file_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"project",false],[2,[7,"/",false],[2,[6,"delete_file",false],[2,[7,"/",false],[2,[3,"file_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// project_delete_project_expert => /project_expert/:project_expert_id/delete(.:format)
  // function(project_expert_id, options)
  project_delete_project_expert_path: Utils.route([["project_expert_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"project_expert",false],[2,[7,"/",false],[2,[3,"project_expert_id",false],[2,[7,"/",false],[2,[6,"delete",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// project_download_email_attachment => /project/download_email_attachment(.:format)
  // function(options)
  project_download_email_attachment_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"project",false],[2,[7,"/",false],[2,[6,"download_email_attachment",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// project_download_file => /project/download_file/:file_id(.:format)
  // function(file_id, options)
  project_download_file_path: Utils.route([["file_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"project",false],[2,[7,"/",false],[2,[6,"download_file",false],[2,[7,"/",false],[2,[3,"file_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// project_download_monthly_report => /project/download_monthly_report(.:format)
  // function(options)
  project_download_monthly_report_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"project",false],[2,[7,"/",false],[2,[6,"download_monthly_report",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// project_get => /project/get/:project_id(.:format)
  // function(project_id, options)
  project_get_path: Utils.route([["project_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"project",false],[2,[7,"/",false],[2,[6,"get",false],[2,[7,"/",false],[2,[3,"project_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// project_get_email => /project/get_email/:message_id(.:format)
  // function(message_id, options)
  project_get_email_path: Utils.route([["message_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"project",false],[2,[7,"/",false],[2,[6,"get_email",false],[2,[7,"/",false],[2,[3,"message_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// project_get_form_data => /project/get_form_data(/:project_id)(.:format)
  // function(options)
  project_get_form_data_path: Utils.route([["project_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"project",false],[2,[7,"/",false],[2,[6,"get_form_data",false],[2,[1,[2,[7,"/",false],[3,"project_id",false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// project_get_project_expert_form_data => /project_expert/:project_expert_id/get_project_expert_form_data(.:format)
  // function(project_expert_id, options)
  project_get_project_expert_form_data_path: Utils.route([["project_expert_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"project_expert",false],[2,[7,"/",false],[2,[3,"project_expert_id",false],[2,[7,"/",false],[2,[6,"get_project_expert_form_data",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// project_get_report_form_data => /project/get_report_form_data(.:format)
  // function(options)
  project_get_report_form_data_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"project",false],[2,[7,"/",false],[2,[6,"get_report_form_data",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// project_list => /project/list(.:format)
  // function(options)
  project_list_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"project",false],[2,[7,"/",false],[2,[6,"list",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// project_list_files => /project/:project_id/list_files(.:format)
  // function(project_id, options)
  project_list_files_path: Utils.route([["project_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"project",false],[2,[7,"/",false],[2,[3,"project_id",false],[2,[7,"/",false],[2,[6,"list_files",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// project_list_filters => /project/list_filters(.:format)
  // function(options)
  project_list_filters_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"project",false],[2,[7,"/",false],[2,[6,"list_filters",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// project_list_project_experts => /project/:project_id/list_project_experts(.:format)
  // function(project_id, options)
  project_list_project_experts_path: Utils.route([["project_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"project",false],[2,[7,"/",false],[2,[3,"project_id",false],[2,[7,"/",false],[2,[6,"list_project_experts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// project_list_projects_and_job => /project/list_projects_and_job(.:format)
  // function(options)
  project_list_projects_and_job_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"project",false],[2,[7,"/",false],[2,[6,"list_projects_and_job",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// project_management_experience_create => /project_management_experience/create(.:format)
  // function(options)
  project_management_experience_create_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"project_management_experience",false],[2,[7,"/",false],[2,[6,"create",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// project_management_experience_delete => /project_management_experience/delete/:project_management_experience_id(.:format)
  // function(project_management_experience_id, options)
  project_management_experience_delete_path: Utils.route([["project_management_experience_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"project_management_experience",false],[2,[7,"/",false],[2,[6,"delete",false],[2,[7,"/",false],[2,[3,"project_management_experience_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// project_management_experience_get_form_data => /project_management_experience/get_form_data(/:project_management_experience_id)(.:format)
  // function(options)
  project_management_experience_get_form_data_path: Utils.route([["project_management_experience_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"project_management_experience",false],[2,[7,"/",false],[2,[6,"get_form_data",false],[2,[1,[2,[7,"/",false],[3,"project_management_experience_id",false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// project_management_experience_list => /project_management_experience/list(.:format)
  // function(options)
  project_management_experience_list_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"project_management_experience",false],[2,[7,"/",false],[2,[6,"list",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// project_management_experience_update => /project_management_experience/update/:project_management_experience_id(.:format)
  // function(project_management_experience_id, options)
  project_management_experience_update_path: Utils.route([["project_management_experience_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"project_management_experience",false],[2,[7,"/",false],[2,[6,"update",false],[2,[7,"/",false],[2,[3,"project_management_experience_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// project_members_list => /project/:project_id/members(.:format)
  // function(project_id, options)
  project_members_list_path: Utils.route([["project_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"project",false],[2,[7,"/",false],[2,[3,"project_id",false],[2,[7,"/",false],[2,[6,"members",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// project_send_email => /project/send_email(.:format)
  // function(options)
  project_send_email_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"project",false],[2,[7,"/",false],[2,[6,"send_email",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// project_type_create => /project_type/create(.:format)
  // function(options)
  project_type_create_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"project_type",false],[2,[7,"/",false],[2,[6,"create",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// project_type_delete => /project_type/delete/:project_type_id(.:format)
  // function(project_type_id, options)
  project_type_delete_path: Utils.route([["project_type_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"project_type",false],[2,[7,"/",false],[2,[6,"delete",false],[2,[7,"/",false],[2,[3,"project_type_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// project_type_get_form_data => /project_type/get_form_data(/:project_type_id)(.:format)
  // function(options)
  project_type_get_form_data_path: Utils.route([["project_type_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"project_type",false],[2,[7,"/",false],[2,[6,"get_form_data",false],[2,[1,[2,[7,"/",false],[3,"project_type_id",false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// project_type_list => /project_type/list(.:format)
  // function(options)
  project_type_list_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"project_type",false],[2,[7,"/",false],[2,[6,"list",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// project_type_list_filters => /project_type/list_filters(.:format)
  // function(options)
  project_type_list_filters_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"project_type",false],[2,[7,"/",false],[2,[6,"list_filters",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// project_type_update => /project_type/update/:project_type_id(.:format)
  // function(project_type_id, options)
  project_type_update_path: Utils.route([["project_type_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"project_type",false],[2,[7,"/",false],[2,[6,"update",false],[2,[7,"/",false],[2,[3,"project_type_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// project_update => /project/update/:project_id(.:format)
  // function(project_id, options)
  project_update_path: Utils.route([["project_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"project",false],[2,[7,"/",false],[2,[6,"update",false],[2,[7,"/",false],[2,[3,"project_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// project_update_extension => /project/:project_id/update_extension/:extension_id(.:format)
  // function(project_id, extension_id, options)
  project_update_extension_path: Utils.route([["project_id",true],["extension_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"project",false],[2,[7,"/",false],[2,[3,"project_id",false],[2,[7,"/",false],[2,[6,"update_extension",false],[2,[7,"/",false],[2,[3,"extension_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// project_update_project_expert => /project_expert/:project_expert_id/update(.:format)
  // function(project_expert_id, options)
  project_update_project_expert_path: Utils.route([["project_expert_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"project_expert",false],[2,[7,"/",false],[2,[3,"project_expert_id",false],[2,[7,"/",false],[2,[6,"update",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// project_update_project_expert_note => /project_expert/:project_expert_id/update_note(.:format)
  // function(project_expert_id, options)
  project_update_project_expert_note_path: Utils.route([["project_expert_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"project_expert",false],[2,[7,"/",false],[2,[3,"project_expert_id",false],[2,[7,"/",false],[2,[6,"update_note",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// project_upload_file => /project/upload_file/:upload_id(.:format)
  // function(upload_id, options)
  project_upload_file_path: Utils.route([["upload_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"project",false],[2,[7,"/",false],[2,[6,"upload_file",false],[2,[7,"/",false],[2,[3,"upload_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// project_validate_monthly_report_params => /project/validate_monthly_report_params(.:format)
  // function(options)
  project_validate_monthly_report_params_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"project",false],[2,[7,"/",false],[2,[6,"validate_monthly_report_params",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// rails_blob_representation => /rails/active_storage/representations/:signed_blob_id/:variation_key/*filename(.:format)
  // function(signed_blob_id, variation_key, filename, options)
  rails_blob_representation_path: Utils.route([["signed_blob_id",true],["variation_key",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"representations",false],[2,[7,"/",false],[2,[3,"signed_blob_id",false],[2,[7,"/",false],[2,[3,"variation_key",false],[2,[7,"/",false],[2,[5,[3,"filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// rails_conductor_inbound_email => /rails/conductor/action_mailbox/inbound_emails/:id(.:format)
  // function(id, options)
  rails_conductor_inbound_email_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// rails_conductor_inbound_email_reroute => /rails/conductor/action_mailbox/:inbound_email_id/reroute(.:format)
  // function(inbound_email_id, options)
  rails_conductor_inbound_email_reroute_path: Utils.route([["inbound_email_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[3,"inbound_email_id",false],[2,[7,"/",false],[2,[6,"reroute",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// rails_conductor_inbound_emails => /rails/conductor/action_mailbox/inbound_emails(.:format)
  // function(options)
  rails_conductor_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rails_direct_uploads => /rails/active_storage/direct_uploads(.:format)
  // function(options)
  rails_direct_uploads_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"direct_uploads",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// rails_disk_service => /rails/active_storage/disk/:encoded_key/*filename(.:format)
  // function(encoded_key, filename, options)
  rails_disk_service_path: Utils.route([["encoded_key",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"disk",false],[2,[7,"/",false],[2,[3,"encoded_key",false],[2,[7,"/",false],[2,[5,[3,"filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// rails_mailgun_inbound_emails => /rails/action_mailbox/mailgun/inbound_emails/mime(.:format)
  // function(options)
  rails_mailgun_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"mailgun",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[6,"mime",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// rails_mandrill_inbound_emails => /rails/action_mailbox/mandrill/inbound_emails(.:format)
  // function(options)
  rails_mandrill_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"mandrill",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rails_mandrill_inbound_health_check => /rails/action_mailbox/mandrill/inbound_emails(.:format)
  // function(options)
  rails_mandrill_inbound_health_check_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"mandrill",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rails_postmark_inbound_emails => /rails/action_mailbox/postmark/inbound_emails(.:format)
  // function(options)
  rails_postmark_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"postmark",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rails_relay_inbound_emails => /rails/action_mailbox/relay/inbound_emails(.:format)
  // function(options)
  rails_relay_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"relay",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rails_sendgrid_inbound_emails => /rails/action_mailbox/sendgrid/inbound_emails(.:format)
  // function(options)
  rails_sendgrid_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"sendgrid",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rails_service_blob => /rails/active_storage/blobs/:signed_id/*filename(.:format)
  // function(signed_id, filename, options)
  rails_service_blob_path: Utils.route([["signed_id",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"blobs",false],[2,[7,"/",false],[2,[3,"signed_id",false],[2,[7,"/",false],[2,[5,[3,"filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// regional_expertise_create => /regional_expertise/create(.:format)
  // function(options)
  regional_expertise_create_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"regional_expertise",false],[2,[7,"/",false],[2,[6,"create",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// regional_expertise_delete => /regional_expertise/delete/:regional_expertise_id(.:format)
  // function(regional_expertise_id, options)
  regional_expertise_delete_path: Utils.route([["regional_expertise_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"regional_expertise",false],[2,[7,"/",false],[2,[6,"delete",false],[2,[7,"/",false],[2,[3,"regional_expertise_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// regional_expertise_get_form_data => /regional_expertise/get_form_data(/:regional_expertise_id)(.:format)
  // function(options)
  regional_expertise_get_form_data_path: Utils.route([["regional_expertise_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"regional_expertise",false],[2,[7,"/",false],[2,[6,"get_form_data",false],[2,[1,[2,[7,"/",false],[3,"regional_expertise_id",false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// regional_expertise_list => /regional_expertise/list(/:parent_id)(.:format)
  // function(options)
  regional_expertise_list_path: Utils.route([["parent_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"regional_expertise",false],[2,[7,"/",false],[2,[6,"list",false],[2,[1,[2,[7,"/",false],[3,"parent_id",false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// regional_expertise_update => /regional_expertise/update/:regional_expertise_id(.:format)
  // function(regional_expertise_id, options)
  regional_expertise_update_path: Utils.route([["regional_expertise_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"regional_expertise",false],[2,[7,"/",false],[2,[6,"update",false],[2,[7,"/",false],[2,[3,"regional_expertise_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// registration_enabled => /registration_enabled(.:format)
  // function(options)
  registration_enabled_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"registration_enabled",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// registration_finished => /register/finished(.:format)
  // function(options)
  registration_finished_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"register",false],[2,[7,"/",false],[2,[6,"finished",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// root => /
  // function(options)
  root_path: Utils.route([], {}, [7,"/",false]),
// send_email_login_link => /send_email_login_link(.:format)
  // function(options)
  send_email_login_link_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"send_email_login_link",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// sent_email_list => /project/sent_email_list(/:recipient_id)(.:format)
  // function(options)
  sent_email_list_path: Utils.route([["recipient_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"project",false],[2,[7,"/",false],[2,[6,"sent_email_list",false],[2,[1,[2,[7,"/",false],[3,"recipient_id",false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// set_locale => /set_locale/:locale(.:format)
  // function(locale, options)
  set_locale_path: Utils.route([["locale",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"set_locale",false],[2,[7,"/",false],[2,[3,"locale",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// setting_email_list => /setting/email/list(.:format)
  // function(options)
  setting_email_list_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setting",false],[2,[7,"/",false],[2,[6,"email",false],[2,[7,"/",false],[2,[6,"list",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// setting_email_template_delete => /setting/email_template/delete/:str_id(.:format)
  // function(str_id, options)
  setting_email_template_delete_path: Utils.route([["str_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setting",false],[2,[7,"/",false],[2,[6,"email_template",false],[2,[7,"/",false],[2,[6,"delete",false],[2,[7,"/",false],[2,[3,"str_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// setting_email_template_get_blank_custom => /setting/email_template/get_blank_custom(.:format)
  // function(options)
  setting_email_template_get_blank_custom_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setting",false],[2,[7,"/",false],[2,[6,"email_template",false],[2,[7,"/",false],[2,[6,"get_blank_custom",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// setting_email_template_list => /setting/email_template/list(.:format)
  // function(options)
  setting_email_template_list_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setting",false],[2,[7,"/",false],[2,[6,"email_template",false],[2,[7,"/",false],[2,[6,"list",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// setting_email_template_list_custom => /setting/email_template/list_custom(.:format)
  // function(options)
  setting_email_template_list_custom_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setting",false],[2,[7,"/",false],[2,[6,"email_template",false],[2,[7,"/",false],[2,[6,"list_custom",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// setting_email_template_update => /setting/email_template/update/:str_id(.:format)
  // function(str_id, options)
  setting_email_template_update_path: Utils.route([["str_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setting",false],[2,[7,"/",false],[2,[6,"email_template",false],[2,[7,"/",false],[2,[6,"update",false],[2,[7,"/",false],[2,[3,"str_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// setting_email_update => /setting/email/update/:key(.:format)
  // function(key, options)
  setting_email_update_path: Utils.route([["key",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setting",false],[2,[7,"/",false],[2,[6,"email",false],[2,[7,"/",false],[2,[6,"update",false],[2,[7,"/",false],[2,[3,"key",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// setting_general_list => /setting/general/list(.:format)
  // function(options)
  setting_general_list_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setting",false],[2,[7,"/",false],[2,[6,"general",false],[2,[7,"/",false],[2,[6,"list",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// setting_general_update => /setting/general/update/:key(.:format)
  // function(key, options)
  setting_general_update_path: Utils.route([["key",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setting",false],[2,[7,"/",false],[2,[6,"general",false],[2,[7,"/",false],[2,[6,"update",false],[2,[7,"/",false],[2,[3,"key",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// setting_status_change_email_template_list => /setting/status_change_email_template/list(.:format)
  // function(options)
  setting_status_change_email_template_list_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setting",false],[2,[7,"/",false],[2,[6,"status_change_email_template",false],[2,[7,"/",false],[2,[6,"list",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// setting_status_change_email_template_update => /setting/status_change_email_template/update/:str_id(.:format)
  // function(str_id, options)
  setting_status_change_email_template_update_path: Utils.route([["str_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setting",false],[2,[7,"/",false],[2,[6,"status_change_email_template",false],[2,[7,"/",false],[2,[6,"update",false],[2,[7,"/",false],[2,[3,"str_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// setting_status_change_list => /setting/status_change/list(.:format)
  // function(options)
  setting_status_change_list_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setting",false],[2,[7,"/",false],[2,[6,"status_change",false],[2,[7,"/",false],[2,[6,"list",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// setting_status_change_update => /setting/status_change/update/:key(.:format)
  // function(key, options)
  setting_status_change_update_path: Utils.route([["key",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setting",false],[2,[7,"/",false],[2,[6,"status_change",false],[2,[7,"/",false],[2,[6,"update",false],[2,[7,"/",false],[2,[3,"key",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// technical_expertise_create => /technical_expertise/create(.:format)
  // function(options)
  technical_expertise_create_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"technical_expertise",false],[2,[7,"/",false],[2,[6,"create",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// technical_expertise_delete => /technical_expertise/delete/:technical_expertise_id(.:format)
  // function(technical_expertise_id, options)
  technical_expertise_delete_path: Utils.route([["technical_expertise_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"technical_expertise",false],[2,[7,"/",false],[2,[6,"delete",false],[2,[7,"/",false],[2,[3,"technical_expertise_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// technical_expertise_get_form_data => /technical_expertise/get_form_data(/:technical_expertise_id)(.:format)
  // function(options)
  technical_expertise_get_form_data_path: Utils.route([["technical_expertise_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"technical_expertise",false],[2,[7,"/",false],[2,[6,"get_form_data",false],[2,[1,[2,[7,"/",false],[3,"technical_expertise_id",false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// technical_expertise_list => /technical_expertise/list(/:parent_id)(.:format)
  // function(options)
  technical_expertise_list_path: Utils.route([["parent_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"technical_expertise",false],[2,[7,"/",false],[2,[6,"list",false],[2,[1,[2,[7,"/",false],[3,"parent_id",false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// technical_expertise_update => /technical_expertise/update/:technical_expertise_id(.:format)
  // function(technical_expertise_id, options)
  technical_expertise_update_path: Utils.route([["technical_expertise_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"technical_expertise",false],[2,[7,"/",false],[2,[6,"update",false],[2,[7,"/",false],[2,[3,"technical_expertise_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// translations => /translations(.:format)
  // function(options)
  translations_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"translations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// update_rails_disk_service => /rails/active_storage/disk/:encoded_token(.:format)
  // function(encoded_token, options)
  update_rails_disk_service_path: Utils.route([["encoded_token",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"disk",false],[2,[7,"/",false],[2,[3,"encoded_token",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// user_agreement_acceptance => /user_agreement/accept/:agreement_id(.:format)
  // function(agreement_id, options)
  user_agreement_acceptance_path: Utils.route([["agreement_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"user_agreement",false],[2,[7,"/",false],[2,[6,"accept",false],[2,[7,"/",false],[2,[3,"agreement_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// user_agreement_create => /user_agreement/create(.:format)
  // function(options)
  user_agreement_create_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"user_agreement",false],[2,[7,"/",false],[2,[6,"create",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// user_agreement_delete => /user_agreement/delete/:agreement_id(.:format)
  // function(agreement_id, options)
  user_agreement_delete_path: Utils.route([["agreement_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"user_agreement",false],[2,[7,"/",false],[2,[6,"delete",false],[2,[7,"/",false],[2,[3,"agreement_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// user_agreement_get => /user_agreement/get/:agreement_id(.:format)
  // function(agreement_id, options)
  user_agreement_get_path: Utils.route([["agreement_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"user_agreement",false],[2,[7,"/",false],[2,[6,"get",false],[2,[7,"/",false],[2,[3,"agreement_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// user_agreement_list => /user_agreement/list(.:format)
  // function(options)
  user_agreement_list_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"user_agreement",false],[2,[7,"/",false],[2,[6,"list",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// user_agreement_update => /user_agreement/update/:agreement_id(.:format)
  // function(agreement_id, options)
  user_agreement_update_path: Utils.route([["agreement_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"user_agreement",false],[2,[7,"/",false],[2,[6,"update",false],[2,[7,"/",false],[2,[3,"agreement_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// user_agreement_update_status => /user_agreement/update_status/:agreement_id(.:format)
  // function(agreement_id, options)
  user_agreement_update_status_path: Utils.route([["agreement_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"user_agreement",false],[2,[7,"/",false],[2,[6,"update_status",false],[2,[7,"/",false],[2,[3,"agreement_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]])}
;
      routes.configure = function(config) {
        return Utils.configure(config);
      };
      routes.config = function() {
        return Utils.config();
      };
      routes.default_serializer = function(object, prefix) {
        return Utils.default_serializer(object, prefix);
      };
      Utils.namespace(root, null, routes);
      return Object.assign({
        "default": routes
      }, routes);
    }
  };

  result = Utils.make();

  if (typeof define === "function" && define.amd) {
    define([], function() {
      return result;
    });
  }

  return result;

}).call(this);

