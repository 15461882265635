import { createStore, applyMiddleware, compose } from 'redux';
// Logger with default options
import logger from 'redux-logger';
import reducer from './reducers/root';

// Devtools can be uncommented if you want use them in development
// Do not commit usage of devtools

// component out during production release
// import { composeWithDevTools } from 'redux-devtools-extension';

// export default function configureStore(initialState) {
//   const store = createStore(
//     reducer,
//     initialState,
//     composeWithDevTools(compose(applyMiddleware(logger))),
//   );
//   return store;
// }
//comment out while development
export default function configureStore(initialState) {
  const store = createStore(
    reducer,
    initialState,
    //composeWithDevTools(
    compose(),
    // applyMiddleware(logger),
    // offline(offlineConfig)code
  );
  return store;
}
