// types of action
const Types = {
  UPDATE_CURRENT_PAGE_INDEX: 'UPDATE_CURRENT_PAGE_INDEX',
};

// actions
const updateCurrentPageIndex = (index) => {
  return {
    type: Types.UPDATE_CURRENT_PAGE_INDEX,
    payload: index,
  };
};

export default {
  Types,
  updateCurrentPageIndex,
};
