import ACTIONS from '../actions/expert_search';
import _ from 'lodash';

const defaultState = {
  includeFreeText: false,
  includeGender: false,
  includeStatus: true,
  includeYearsOfExperience: false,
  includeNationality: false,
  includeReleventPosition: false,
  includeExpertiseType: false,
  includeEducation: false,
  includeDonorExperinece: false,
  includeLanguageSkills: false,
  includeAge: false,
  includeProfileUpdated: false,
  includeMissions: false,
  includeDrivingLicence: false,
  includeFsbCourses: false,
  includeFsbCoursesYears: false,
  includeTechnicalExpertise: false,
  includeRegionalExperience: false,
  includeDocuments: false,
  includeInvertSearch: false,
  freeText: '',
  statusFrom: undefined,
  statusTo: undefined,
  freeTextTargets: [
    'CV_FILE',
    'LANGUAGE_ADDITIONAL_INFO',
    'ADDITIONAL_INFO',
    'EDUCATION_ADDITIONAL_INFO',
    'BASIC_INFO',
  ],
  statusOptions: [
    {
      label: I18n.t('candidate'),
      value: 'CANDIDATE',
    },
  ],

  missionExpertStatus: [
    {
      label: I18n.t('candidate'),
      value: 'CANDIDATE',
    },
    {
      label: I18n.t('contracted'),
      value: 'CONTRACTED',
    },
  ],
  genderOptions: [],
  experienceYearsOptions: [],
  nationalityOptions: [],
  expertiseTypeOptions: [],
  drivingLicenceTypeOptions: [],
  expiringDocumentOptions: [],
  releventPositionOptions: [],
  educationOptions: [],
  fsbCourses: [],
  donorOptions: [],
  missions: [],
  missionFrom: undefined,
  missionTo: undefined,
  missionProjects: [],
  languageSkills: {},
  technicalExpertise: {},
  regionalExperience: {},
  minAge: '0',
  maxAge: '100',
  profileUpdatedFrom: undefined,
  profileUpdatedTo: undefined,
  documentsObtainedBefore: undefined,
  documentsObtainedAfter: undefined,
  fsbCourseStarted: undefined,
  fsbCourseEnded: undefined,
  resultCount: 0,
  searchCriteria: {},
  // operators
  nationalityOperator: 'AND',
  expertiseTypeOperator: 'AND',
  drivingLicenseTypeOperator: 'AND',
  documentTypeOperator: 'AND',
  releventPositionTypeOperator: 'AND',
  educationTypeOperator: 'AND',
  donorTypeOperator: 'AND',
  languageSkillsOperator: 'AND',
  fsbCoursesOperator: 'AND',
  technicalExpertiseOperator: 'AND',
  regionalExperienceOperator: 'AND',
};

const expertSearchReducer = (state = defaultState, action) => {
  switch (action.type) {
    case ACTIONS.Types.UPDATE_FREE_TEXT: {
      let newState = _.cloneDeep(state);
      newState.freeText = action.payload;
      return newState;
    }

    case ACTIONS.Types.UPDATE_FREE_TEXT_TARGETS: {
      let newState = _.cloneDeep(state);
      newState.freeTextTargets = action.payload;
      return newState;
    }

    case ACTIONS.Types.UPDATE_GENDER_OPTIONS: {
      let newState = _.cloneDeep(state);
      newState.genderOptions = action.payload;
      return newState;
    }

    case ACTIONS.Types.UPDATE_EXPERIENCE_YEARS_OPTIONS: {
      let newState = _.cloneDeep(state);
      newState.experienceYearsOptions = action.payload;
      return newState;
    }

    case ACTIONS.Types.UPDATE_STATUS_OPTIONS: {
      let newState = _.cloneDeep(state);
      newState.statusOptions = action.payload;
      return newState;
    }

    case ACTIONS.Types.UPDATE_STATUS_FROM: {
      let newState = _.cloneDeep(state);
      newState.statusFrom = action.payload;
      return newState;
    }

    case ACTIONS.Types.UPDATE_STATUS_TO: {
      let newState = _.cloneDeep(state);
      newState.statusTo = action.payload;
      return newState;
    }

    case ACTIONS.Types.UPDATE_NATIONALITY_OPTIONS: {
      let newState = _.cloneDeep(state);
      newState.nationalityOptions = action.payload;
      return newState;
    }

    case ACTIONS.Types.UPDATE_EXPERTISE_TYPE_OPTIONS: {
      let newState = _.cloneDeep(state);
      newState.expertiseTypeOptions = action.payload;
      return newState;
    }

    case ACTIONS.Types.UPDATE_DRIVING_LICENCE_TYPE_OPTIONS: {
      let newState = _.cloneDeep(state);
      newState.drivingLicenseTypeOptions = action.payload;
      return newState;
    }

    case ACTIONS.Types.UPDATE_EXPIRING_DOCUMENT_OPTIONS: {
      let newState = _.cloneDeep(state);
      newState.expiringDocumentOptions = action.payload;
      return newState;
    }

    case ACTIONS.Types.UPDATE_DONOR_OPTIONS: {
      let newState = _.cloneDeep(state);
      newState.donorOptions = action.payload;
      return newState;
    }

    case ACTIONS.Types.UPDATE_EDUCATION_OPTIONS: {
      let newState = _.cloneDeep(state);
      newState.educationOptions = action.payload;
      return newState;
    }

    case ACTIONS.Types.UPDATE_FSB_COURSES: {
      let newState = _.cloneDeep(state);
      newState.fsbCourses = action.payload;
      return newState;
    }

    case ACTIONS.Types.UPDATE_LANGUAGE_SKILLS: {
      let newState = _.cloneDeep(state);
      newState.languageSkills = action.payload;
      return newState;
    }

    case ACTIONS.Types.UPDATE_MIN_AGE: {
      let newState = _.cloneDeep(state);
      newState.minAge = action.payload;
      return newState;
    }

    case ACTIONS.Types.UPDATE_MAX_AGE: {
      let newState = _.cloneDeep(state);
      newState.maxAge = action.payload;
      return newState;
    }

    case ACTIONS.Types.UPDATE_PROFILE_UPDATED_FROM: {
      let newState = _.cloneDeep(state);
      newState.profileUpdatedFrom = action.payload;
      return newState;
    }

    case ACTIONS.Types.UPDATE_PROFILE_UPDATED_TO: {
      let newState = _.cloneDeep(state);
      newState.profileUpdatedTo = action.payload;
      return newState;
    }

    case ACTIONS.Types.UPDATE_FSB_COURSE_STARTED: {
      let newState = _.cloneDeep(state);
      newState.fsbCourseStarted = action.payload;
      return newState;
    }

    case ACTIONS.Types.UPDATE_FSB_COURSE_ENDED: {
      let newState = _.cloneDeep(state);
      newState.fsbCourseEnded = action.payload;
      return newState;
    }

    case ACTIONS.Types.UPDATE_MISSIONS_EXPERT_STATUS: {
      let newState = _.cloneDeep(state);
      newState.missionExpertStatus = action.payload;
      return newState;
    }

    case ACTIONS.Types.UPDATE_MISSIONS: {
      let newState = _.cloneDeep(state);
      newState.missions = action.payload;
      return newState;
    }

    case ACTIONS.Types.UPDATE_MISSION_FROM: {
      let newState = _.cloneDeep(state);
      newState.missionFrom = action.payload;
      return newState;
    }

    case ACTIONS.Types.UPDATE_MISSION_TO: {
      let newState = _.cloneDeep(state);
      newState.missionTo = action.payload;
      return newState;
    }

    case ACTIONS.Types.UPDATE_MISSION_PROJECTS: {
      let newState = _.cloneDeep(state);
      newState.missionProjects = action.payload;
      return newState;
    }

    case ACTIONS.Types.UPDATE_DOCUMENTS_OBTAINED_BEFORE: {
      let newState = _.cloneDeep(state);
      newState.documentsObtainedBefore = action.payload;
      return newState;
    }

    case ACTIONS.Types.UPDATE_DOCUMENTS_OBTAINED_AFTER: {
      let newState = _.cloneDeep(state);
      newState.documentsObtainedAfter = action.payload;
      return newState;
    }

    case ACTIONS.Types.UPDATE_TECHNICAL_EXPERTISE: {
      let newState = _.cloneDeep(state);
      newState.technicalExpertise = action.payload;
      return newState;
    }

    case ACTIONS.Types.UPDATE_REGIONAL_EXPERIENCE: {
      let newState = _.cloneDeep(state);
      newState.regionalExperience = action.payload;
      return newState;
    }

    case ACTIONS.Types.UPDATE_RESULT_COUNT: {
      let newState = _.cloneDeep(state);
      newState.resultCount = action.payload;
      return newState;
    }
    // Operator reducers

    case ACTIONS.Types.UPDATE_NATIONALITY_OPERATOR: {
      let newState = _.cloneDeep(state);
      newState.nationalityOperator = action.payload;
      return newState;
    }

    case ACTIONS.Types.UPDATE_EDUCATION_TYPE_OPERATOR: {
      let newState = _.cloneDeep(state);
      newState.educationTypeOperator = action.payload;
      return newState;
    }

    case ACTIONS.Types.UPDATE_EXPERTISE_TYPE_OPERATOR: {
      let newState = _.cloneDeep(state);
      newState.expertiseTypeOperator = action.payload;
      return newState;
    }

    case ACTIONS.Types.UPDATE_DRIVING_LICENCE_TYPE_OPERATOR: {
      let newState = _.cloneDeep(state);
      newState.drivingLicenseTypeOperator = action.payload;
      return newState;
    }

    case ACTIONS.Types.UPDATE_DOCUMENT_TYPE_OPERATOR: {
      let newState = _.cloneDeep(state);
      newState.documentTypeOperator = action.payload;
      return newState;
    }

    case ACTIONS.Types.UPDATE_RELEVENT_POSITION_TYPE_OPERATOR: {
      let newState = _.cloneDeep(state);
      newState.releventPositionTypeOperator = action.payload;
      return newState;
    }

    case ACTIONS.Types.UPDATE_LANGUAGE_SKILLS_OPERATOR: {
      let newState = _.cloneDeep(state);
      newState.languageSkillsOperator = action.payload;
      return newState;
    }

    case ACTIONS.Types.UPDATE_RELEVENT_POSITION_OPTIONS: {
      let newState = _.cloneDeep(state);
      newState.releventPositionOptions = action.payload;
      return newState;
    }

    case ACTIONS.Types.UPDATE_DONOR_TYPE_OPERATOR: {
      let newState = _.cloneDeep(state);
      newState.donorTypeOperator = action.payload;
      return newState;
    }

    case ACTIONS.Types.UPDATE_FSB_COURSES_OPERATOR: {
      let newState = _.cloneDeep(state);
      newState.fsbCoursesOperator = action.payload;
      return newState;
    }

    case ACTIONS.Types.UPDATE_TECHNICAL_EXPERTISE_OPERATOR: {
      let newState = _.cloneDeep(state);
      newState.technicalExpertiseOperator = action.payload;
      return newState;
    }

    case ACTIONS.Types.UPDATE_REGIONAL_EXPERIENCE_OPERATOR: {
      let newState = _.cloneDeep(state);
      newState.regionalExperienceOperator = action.payload;
      return newState;
    }

    case ACTIONS.Types.UPDATE_SEARCH_CRITERIA: {
      let newState = _.cloneDeep(state);
      newState.searchCriteria = action.payload;
      return newState;
    }

    // Toggle reducers
    case ACTIONS.Types.TOGGLE_INCLUDE_FREE_TEXT: {
      let newState = _.cloneDeep(state);
      newState.includeFreeText = !state.includeFreeText;
      return newState;
    }

    case ACTIONS.Types.TOGGLE_INCLUDE_GENDER: {
      let newState = _.cloneDeep(state);
      newState.includeGender = !state.includeGender;
      return newState;
    }

    case ACTIONS.Types.TOGGLE_INCLUDE_YEARS_OF_EXPERIENCE: {
      let newState = _.cloneDeep(state);
      newState.includeYearsOfExperience = !state.includeYearsOfExperience;
      return newState;
    }

    case ACTIONS.Types.TOGGLE_INCLUDE_GENDER: {
      let newState = _.cloneDeep(state);
      newState.includeGender = !state.includeGender;
      return newState;
    }

    case ACTIONS.Types.TOGGLE_INCLUDE_STATUS: {
      let newState = _.cloneDeep(state);
      newState.includeStatus = !state.includeStatus;
      return newState;
    }

    case ACTIONS.Types.TOGGLE_INCLUDE_NATIONALITY: {
      let newState = _.cloneDeep(state);
      newState.includeNationality = !state.includeNationality;
      return newState;
    }

    case ACTIONS.Types.TOGGLE_INCLUDE_EXPERTISE_TYPE: {
      let newState = _.cloneDeep(state);
      newState.includeExpertiseType = !state.includeExpertiseType;
      return newState;
    }

    case ACTIONS.Types.TOGGLE_INCLUDE_DRIVING_LICENCE: {
      let newState = _.cloneDeep(state);
      newState.includeDrivingLicence = !state.includeDrivingLicence;
      return newState;
    }

    case ACTIONS.Types.TOGGLE_INCLUDE_AGE: {
      let newState = _.cloneDeep(state);
      newState.includeAge = !state.includeAge;
      return newState;
    }

    case ACTIONS.Types.TOGGLE_INCLUDE_PROFILE_UPDATED: {
      let newState = _.cloneDeep(state);
      newState.includeProfileUpdated = !state.includeProfileUpdated;
      return newState;
    }

    case ACTIONS.Types.TOGGLE_INCLUDE_DOCUMENTS: {
      let newState = _.cloneDeep(state);
      newState.includeDocuments = !state.includeDocuments;
      return newState;
    }

    case ACTIONS.Types.TOGGLE_INCLUDE_RELEVENT_POSITION: {
      let newState = _.cloneDeep(state);
      newState.includeReleventPosition = !state.includeReleventPosition;
      return newState;
    }

    case ACTIONS.Types.TOGGLE_INCLUDE_EDUCATION: {
      let newState = _.cloneDeep(state);
      newState.includeEducation = !state.includeEducation;
      return newState;
    }

    case ACTIONS.Types.TOGGLE_INCLUDE_DONOR_EXPERIENCE: {
      let newState = _.cloneDeep(state);
      newState.includeDonorExperinece = !state.includeDonorExperinece;
      return newState;
    }

    case ACTIONS.Types.TOGGLE_INCLUDE_LANGUAGE_SKILLS: {
      let newState = _.cloneDeep(state);
      newState.includeLanguageSkills = !state.includeLanguageSkills;
      return newState;
    }

    case ACTIONS.Types.TOGGLE_INCLUDE_FSB_COURSES: {
      let newState = _.cloneDeep(state);
      newState.includeFsbCourses = !state.includeFsbCourses;
      return newState;
    }

    case ACTIONS.Types.TOGGLE_INCLUDE_FSB_COURSES_YEARS: {
      let newState = _.cloneDeep(state);
      newState.includeFsbCoursesYears = !!action.payload;
      return newState;
    }

    case ACTIONS.Types.TOGGLE_INCLUDE_MISSIONS: {
      let newState = _.cloneDeep(state);
      newState.includeMissions = !state.includeMissions;
      return newState;
    }

    case ACTIONS.Types.TOGGLE_INCLUDE_TECHNICAL_EXPERTISE: {
      let newState = _.cloneDeep(state);
      newState.includeTechnicalExpertise = !state.includeTechnicalExpertise;
      return newState;
    }

    case ACTIONS.Types.TOGGLE_INCLUDE_REGIONAL_EXPERIENCE: {
      let newState = _.cloneDeep(state);
      newState.includeRegionalExperience = !state.includeRegionalExperience;
      return newState;
    }

    case ACTIONS.Types.CLEAR_SEARCH: {
      return defaultState;
    }

    default:
      return state;
  }
};
export default expertSearchReducer;
